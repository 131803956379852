import React from 'react'
import { FormattedMessage } from 'react-intl';

export const fullDataAreaBlock = (isoline) => {
  return (
    <div>
      <strong><FormattedMessage id="demo.full.isolines_label" /></strong>
      {isoline.ewkt !== undefined && <p><FormattedMessage id="demo.full.ewkt" /><span>{isoline.ewkt}</span></p>}
      {isoline.mode !== undefined && <p><FormattedMessage id="demo.full.mode" /><span>{isoline.mode}</span></p>}
      {isoline.range !== undefined && <p><FormattedMessage id="demo.full.range" /><span>{isoline.range}</span></p>}
      {isoline.type !== undefined && <p><FormattedMessage id="demo.full.type" /><span>{isoline.type}</span></p>}
    </div>
  )
}