import Typography from "typography"

const typography = new Typography({
  baseFontSize: "16px",
  baseLineHeight: 1.666,
  headerFontFamily: ["Montserrat", "sans-serif"],
  bodyFontFamily: ["Montserrat", "sans-serif"],
  headerWeight: "500",
  scaleRatio: 2.5,
  googleFonts: [
    {
      name: 'Montserrat',
      styles: [
        '300',
        '400',
        '500',
        '600',
        '700&display=swap'
      ],
    }
  ]
})

export default typography