import React from "react"
import styles from "./features.module.css"
import PropTypes from "prop-types"
import Section from "../Section/section"
import FeatureIcon from "../FeatureIcon/featureIcon"

const Features = ({ items, section, small }) => (
  section ? (
    <Section>
      <div className={styles.features}>
        {items.map((item, i) => {
          return <FeatureIcon key={i} text={item.text} icon={item.icon} />
        })}
      </div>
    </Section >
  ) : (
      <div className={small ? styles.features + " " + styles.smallFeatures : styles.features}>
        {items.map((item, i) => {
          return <FeatureIcon key={i} text={item.text} icon={item.icon} small={small} />
        })}
      </div>
    )
)

Features.propTypes = {
  items: PropTypes.array,
  section: PropTypes.bool,
  small: PropTypes.bool
}

Features.defaultProps = {
  items: [],
  section: true,
  small: false
}

export default Features
