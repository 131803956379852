import React from 'react'
import { FormattedMessage } from 'react-intl';

export const fullDataBlock = (data) => {
  return (
    <div>
      <strong><FormattedMessage id="demo.full.catchment_label" /></strong>
      {data.voiv !== undefined && <p><FormattedMessage id="demo.full.voiv" /><span>{data.voiv}</span></p>}
      {data.pov !== undefined && <p><FormattedMessage id="demo.full.pov" /><span>{data.pov}</span></p>}
      {data.mun !== undefined && <p><FormattedMessage id="demo.full.mun" /><span>{data.mun}</span></p>}
      {data.mun_type !== undefined && <p><FormattedMessage id="demo.full.mun_type" /><span>{data.mun_type}</span></p>}
      {data.msegm !== undefined && <p><FormattedMessage id="demo.full.msegm" /><span>{data.msegm}</span></p>}
      {data.mwg7 !== undefined && <p><FormattedMessage id="demo.full.mwg7" /><span>{data.mwg7}</span></p>}
      {data.mwg7d !== undefined && <p><FormattedMessage id="demo.full.mwg7d" /><span>{data.mwg7d}</span></p>}
      {data.ctype !== undefined && <p><FormattedMessage id="demo.full.ctype" /><span>{data.ctype}</span></p>}
      {data.area_km2 !== undefined && <p><FormattedMessage id="demo.full.area_km2" /><span>{data.area_km2}</span></p>}
      {data.bld_t !== undefined && <p><FormattedMessage id="demo.full.bld_t" /><span>{data.bld_t}</span></p>}
      {data.bld_r !== undefined && <p><FormattedMessage id="demo.full.bld_r" /><span>{data.bld_r}</span></p>}
      {data.apts_r !== undefined && <p><FormattedMessage id="demo.full.apts_r" /><span>{data.apts_r}</span></p>}
      {data.apts_rp !== undefined && <p><FormattedMessage id="demo.full.apts_rp" /><span>{data.apts_rp}</span></p>}
      {data.pop_tot !== undefined && <p><FormattedMessage id="demo.full.pop_tot" /><span>{data.pop_tot}</span></p>}
      {data.hh_size !== undefined && <p><FormattedMessage id="demo.full.hh_size" /><span>{data.hh_size}</span></p>}
      {data.pop_den !== undefined && <p><FormattedMessage id="demo.full.pop_den" /><span>{data.pop_den}</span></p>}
      {data.pop_ax_t !== undefined && <p><FormattedMessage id="demo.full.pop_ax_t" /><span>{data.pop_ax_t}</span></p>}
      {data.pprf !== undefined && <p><FormattedMessage id="demo.full.pprf" /><span>{data.pprf}</span></p>}
      {data.pprm !== undefined && <p><FormattedMessage id="demo.full.pprm" /><span>{data.pprm}</span></p>}
      {data.ppr0004 !== undefined && <p><FormattedMessage id="demo.full.ppr0004" /><span>{data.ppr0004}</span></p>}
      {data.ppr0509 !== undefined && <p><FormattedMessage id="demo.full.ppr0509" /><span>{data.ppr0509}</span></p>}
      {data.ppr1014 !== undefined && <p><FormattedMessage id="demo.full.ppr1014" /><span>{data.ppr1014}</span></p>}
      {data.ppr1519 !== undefined && <p><FormattedMessage id="demo.full.ppr1519" /><span>{data.ppr1519}</span></p>}
      {data.ppr2024 !== undefined && <p><FormattedMessage id="demo.full.ppr2024" /><span>{data.ppr2024}</span></p>}
      {data.ppr2529 !== undefined && <p><FormattedMessage id="demo.full.ppr2529" /><span>{data.ppr2529}</span></p>}
      {data.ppr3034 !== undefined && <p><FormattedMessage id="demo.full.ppr3034" /><span>{data.ppr3034}</span></p>}
      {data.ppr3539 !== undefined && <p><FormattedMessage id="demo.full.ppr3539" /><span>{data.ppr3539}</span></p>}
      {data.ppr4044 !== undefined && <p><FormattedMessage id="demo.full.ppr4044" /><span>{data.ppr4044}</span></p>}
      {data.ppr4549 !== undefined && <p><FormattedMessage id="demo.full.ppr4549" /><span>{data.ppr4549}</span></p>}
      {data.ppr5054 !== undefined && <p><FormattedMessage id="demo.full.ppr5054" /><span>{data.ppr5054}</span></p>}
      {data.ppr5559 !== undefined && <p><FormattedMessage id="demo.full.ppr5559" /><span>{data.ppr5559}</span></p>}
      {data.ppr6064 !== undefined && <p><FormattedMessage id="demo.full.ppr6064" /><span>{data.ppr6064}</span></p>}
      {data.ppr6569 !== undefined && <p><FormattedMessage id="demo.full.ppr6569" /><span>{data.ppr6569}</span></p>}
      {data.ppr7074 !== undefined && <p><FormattedMessage id="demo.full.ppr7074" /><span>{data.ppr7074}</span></p>}
      {data.ppr7599 !== undefined && <p><FormattedMessage id="demo.full.ppr7599" /><span>{data.ppr7599}</span></p>}
      {data.inbld_t !== undefined && <p><FormattedMessage id="demo.full.inbld_t" /><span>{data.inbld_t}</span></p>}
      {data.poc_tot !== undefined && <p><FormattedMessage id="demo.full.poc_tot" /><span>{data.poc_tot}</span></p>}
      {data.crc_tot !== undefined && <p><FormattedMessage id="demo.full.crc_tot" /><span>{data.crc_tot}</span></p>}
      {data.workers !== undefined && <p><FormattedMessage id="demo.full.workers" /><span>{data.workers}</span></p>}
      {data.iahu_x !== undefined && <p><FormattedMessage id="demo.full.iahu_x" /><span>{data.iahu_x}</span></p>}
      {data.iahu_p !== undefined && <p><FormattedMessage id="demo.full.iahu_p" /><span>{data.iahu_p}</span></p>}
      {data.poi_tot !== undefined && <p><FormattedMessage id="demo.full.poi_tot" /><span>{data.poi_tot}</span></p>}
      {data.poi_atm !== undefined && <p><FormattedMessage id="demo.full.poi_atm" /><span>{data.poi_atm}</span></p>}
      {data.poi_bank !== undefined && <p><FormattedMessage id="demo.full.poi_bank" /><span>{data.poi_bank}</span></p>}
      {data.poi_mall !== undefined && <p><FormattedMessage id="demo.full.poi_mall" /><span>{data.poi_mall}</span></p>}
      {data.poi_edut !== undefined && <p><FormattedMessage id="demo.full.poi_edut" /><span>{data.poi_edut}</span></p>}
      {data.poi_eduk !== undefined && <p><FormattedMessage id="demo.full.poi_eduk" /><span>{data.poi_eduk}</span></p>}
      {data.poi_edup !== undefined && <p><FormattedMessage id="demo.full.poi_edup" /><span>{data.poi_edup}</span></p>}
      {data.poi_edus !== undefined && <p><FormattedMessage id="demo.full.poi_edus" /><span>{data.poi_edus}</span></p>}
      {data.poi_eduh !== undefined && <p><FormattedMessage id="demo.full.poi_eduh" /><span>{data.poi_eduh}</span></p>}
      {data.poi_ret !== undefined && <p><FormattedMessage id="demo.full.poi_ret" /><span>{data.poi_ret}</span></p>}
      {data.poi_rcon !== undefined && <p><FormattedMessage id="demo.full.poi_rcon" /><span>{data.poi_rcon}</span></p>}
      {data.poi_rdro !== undefined && <p><FormattedMessage id="demo.full.poi_rdro" /><span>{data.poi_rdro}</span></p>}
      {data.poi_rdys !== undefined && <p><FormattedMessage id="demo.full.poi_rdys" /><span>{data.poi_rdys}</span></p>}
      {data.poi_rhip !== undefined && <p><FormattedMessage id="demo.full.poi_rhip" /><span>{data.poi_rhip}</span></p>}
      {data.poi_rsup !== undefined && <p><FormattedMessage id="demo.full.poi_rsup" /><span>{data.poi_rsup}</span></p>}
      {data.poi_haz !== undefined && <p><FormattedMessage id="demo.full.poi_haz" /><span>{data.poi_haz}</span></p>}
      {data.poi_hor !== undefined && <p><FormattedMessage id="demo.full.poi_hor" /><span>{data.poi_hor}</span></p>}
      {data.poi_cul !== undefined && <p><FormattedMessage id="demo.full.poi_cul" /><span>{data.poi_cul}</span></p>}
      {data.poi_poo !== undefined && <p><FormattedMessage id="demo.full.poi_poo" /><span>{data.poi_poo}</span></p>}
      {data.poi_enc !== undefined && <p><FormattedMessage id="demo.full.poi_enc" /><span>{data.poi_enc}</span></p>}
      {data.poi_gst !== undefined && <p><FormattedMessage id="demo.full.poi_gst" /><span>{data.poi_gst}</span></p>}
      {data.poi_trst !== undefined && <p><FormattedMessage id="demo.full.poi_trst" /><span>{data.poi_trst}</span></p>}
      {data.poi_trt !== undefined && <p><FormattedMessage id="demo.full.poi_trt" /><span>{data.poi_trt}</span></p>}
      {data.poi_tsto !== undefined && <p><FormattedMessage id="demo.full.poi_tsto" /><span>{data.poi_tsto}</span></p>}
      {data.poi_ps !== undefined && <p><FormattedMessage id="demo.full.poi_ps" /><span>{data.poi_ps}</span></p>}
      {data.poi_hea !== undefined && <p><FormattedMessage id="demo.full.poi_hea" /><span>{data.poi_hea}</span></p>}
      {data.poi_heap !== undefined && <p><FormattedMessage id="demo.full.poi_heap" /><span>{data.poi_heap}</span></p>}
      {data.alc_t !== undefined && <p><FormattedMessage id="demo.full.alc_t" /><span>{data.alc_t}</span></p>}
      {data.alc_r !== undefined && <p><FormattedMessage id="demo.full.alc_r" /><span>{data.alc_r}</span></p>}
      {data.alc_h !== undefined && <p><FormattedMessage id="demo.full.alc_h" /><span>{data.alc_h}</span></p>}
      {data.pod_atm !== undefined && <p><FormattedMessage id="demo.full.pod_atm" /><span>{data.pod_atm}</span></p>}
      {data.pod_bank !== undefined && <p><FormattedMessage id="demo.full.pod_bank" /><span>{data.pod_bank}</span></p>}
      {data.pod_mall !== undefined && <p><FormattedMessage id="demo.full.pod_mall" /><span>{data.pod_mall}</span></p>}
      {data.pod_edut !== undefined && <p><FormattedMessage id="demo.full.pod_edut" /><span>{data.pod_edut}</span></p>}
      {data.pod_eduk !== undefined && <p><FormattedMessage id="demo.full.pod_eduk" /><span>{data.pod_eduk}</span></p>}
      {data.pod_edup !== undefined && <p><FormattedMessage id="demo.full.pod_edup" /><span>{data.pod_edup}</span></p>}
      {data.pod_edus !== undefined && <p><FormattedMessage id="demo.full.pod_edus" /><span>{data.pod_edus}</span></p>}
      {data.pod_eduh !== undefined && <p><FormattedMessage id="demo.full.pod_eduh" /><span>{data.pod_eduh}</span></p>}
      {data.pod_rt !== undefined && <p><FormattedMessage id="demo.full.pod_rt" /><span>{data.pod_rt}</span></p>}
      {data.pod_rcon !== undefined && <p><FormattedMessage id="demo.full.pod_rcon" /><span>{data.pod_rcon}</span></p>}
      {data.pod_rdro !== undefined && <p><FormattedMessage id="demo.full.pod_rdro" /><span>{data.pod_rdro}</span></p>}
      {data.pod_rdys !== undefined && <p><FormattedMessage id="demo.full.pod_rdys" /><span>{data.pod_rdys}</span></p>}
      {data.pod_rhip !== undefined && <p><FormattedMessage id="demo.full.pod_rhip" /><span>{data.pod_rhip}</span></p>}
      {data.pod_rsup !== undefined && <p><FormattedMessage id="demo.full.pod_rsup" /><span>{data.pod_rsup}</span></p>}
      {data.pod_haz !== undefined && <p><FormattedMessage id="demo.full.pod_haz" /><span>{data.pod_haz}</span></p>}
      {data.pod_hor !== undefined && <p><FormattedMessage id="demo.full.pod_hor" /><span>{data.pod_hor}</span></p>}
      {data.pod_cul !== undefined && <p><FormattedMessage id="demo.full.pod_cul" /><span>{data.pod_cul}</span></p>}
      {data.pod_poo !== undefined && <p><FormattedMessage id="demo.full.pod_poo" /><span>{data.pod_poo}</span></p>}
      {data.pod_enc !== undefined && <p><FormattedMessage id="demo.full.pod_enc" /><span>{data.pod_enc}</span></p>}
      {data.pod_gst !== undefined && <p><FormattedMessage id="demo.full.pod_gst" /><span>{data.pod_gst}</span></p>}
      {data.pod_trt !== undefined && <p><FormattedMessage id="demo.full.pod_trt" /><span>{data.pod_trt}</span></p>}
      {data.pod_trst !== undefined && <p><FormattedMessage id="demo.full.pod_trst" /><span>{data.pod_trst}</span></p>}
      {data.pod_tsto !== undefined && <p><FormattedMessage id="demo.full.pod_tsto" /><span>{data.pod_tsto}</span></p>}
      {data.pod_ps !== undefined && <p><FormattedMessage id="demo.full.pod_ps" /><span>{data.pod_ps}</span></p>}
      {data.pod_hea !== undefined && <p><FormattedMessage id="demo.full.pod_hea" /><span>{data.pod_hea}</span></p>}
      {data.pod_heap !== undefined && <p><FormattedMessage id="demo.full.pod_heap" /><span>{data.pod_heap}</span></p>}
      {data.pbne_rb !== undefined && <p><FormattedMessage id="demo.full.pbne_rb" /><span>{data.pbne_rb}</span></p>}
      {data.pbne_rd !== undefined && <p><FormattedMessage id="demo.full.pbne_rd" /><span>{data.pbne_rd}</span></p>}
      {data.pbne_rc !== undefined && <p><FormattedMessage id="demo.full.pbne_rc" /><span>{data.pbne_rc}</span></p>}
      {data.pbne_fs !== undefined && <p><FormattedMessage id="demo.full.pbne_fs" /><span>{data.pbne_fs}</span></p>}
      {data.pbne_rts !== undefined && <p><FormattedMessage id="demo.full.pbne_rts" /><span>{data.pbne_rts}</span></p>}
      {data.pbne_rf !== undefined && <p><FormattedMessage id="demo.full.pbne_rf" /><span>{data.pbne_rf}</span></p>}
      {data.pbne_rur !== undefined && <p><FormattedMessage id="demo.full.pbne_rur" /><span>{data.pbne_rur}</span></p>}
      {data.plau_for !== undefined && <p><FormattedMessage id="demo.full.plau_for" /><span>{data.plau_for}</span></p>}
      {data.plau_aip !== undefined && <p><FormattedMessage id="demo.full.plau_aip" /><span>{data.plau_aip}</span></p>}
      {data.plau_inc !== undefined && <p><FormattedMessage id="demo.full.plau_inc" /><span>{data.plau_inc}</span></p>}
      {data.plau_prz !== undefined && <p><FormattedMessage id="demo.full.plau_prz" /><span>{data.plau_prz}</span></p>}
      {data.plau_agr !== undefined && <p><FormattedMessage id="demo.full.plau_agr" /><span>{data.plau_agr}</span></p>}
      {data.plau_spo !== undefined && <p><FormattedMessage id="demo.full.plau_spo" /><span>{data.plau_spo}</span></p>}
      {data.plau_ror !== undefined && <p><FormattedMessage id="demo.full.plau_ror" /><span>{data.plau_ror}</span></p>}
      {data.plau_wat !== undefined && <p><FormattedMessage id="demo.full.plau_wat" /><span>{data.plau_wat}</span></p>}
      {data.plau_cbd !== undefined && <p><FormattedMessage id="demo.full.plau_cbd" /><span>{data.plau_cbd}</span></p>}
      {data.plau_cbc !== undefined && <p><FormattedMessage id="demo.full.plau_cbc" /><span>{data.plau_cbc}</span></p>}
      {data.mhh_size !== undefined && <p><FormattedMessage id="demo.full.mhh_size" /><span>{data.mhh_size}</span></p>}
      {data.mpop_den !== undefined && <p><FormattedMessage id="demo.full.mpop_den" /><span>{data.mpop_den}</span></p>}
      {data.mpop_axt !== undefined && <p><FormattedMessage id="demo.full.mpop_axt" /><span>{data.mpop_axt}</span></p>}
      {data.mpprf !== undefined && <p><FormattedMessage id="demo.full.mpprf" /><span>{data.mpprf}</span></p>}
      {data.mpprm !== undefined && <p><FormattedMessage id="demo.full.mpprm" /><span>{data.mpprm}</span></p>}
      {data.mppr0004 !== undefined && <p><FormattedMessage id="demo.full.mppr0004" /><span>{data.mppr0004}</span></p>}
      {data.mppr0509 !== undefined && <p><FormattedMessage id="demo.full.mppr0509" /><span>{data.mppr0509}</span></p>}
      {data.mppr1014 !== undefined && <p><FormattedMessage id="demo.full.mppr1014" /><span>{data.mppr1014}</span></p>}
      {data.mppr1519 !== undefined && <p><FormattedMessage id="demo.full.mppr1519" /><span>{data.mppr1519}</span></p>}
      {data.mppr2024 !== undefined && <p><FormattedMessage id="demo.full.mppr2024" /><span>{data.mppr2024}</span></p>}
      {data.mppr2529 !== undefined && <p><FormattedMessage id="demo.full.mppr2529" /><span>{data.mppr2529}</span></p>}
      {data.mppr3034 !== undefined && <p><FormattedMessage id="demo.full.mppr3034" /><span>{data.mppr3034}</span></p>}
      {data.mppr3539 !== undefined && <p><FormattedMessage id="demo.full.mppr3539" /><span>{data.mppr3539}</span></p>}
      {data.mppr4044 !== undefined && <p><FormattedMessage id="demo.full.mppr4044" /><span>{data.mppr4044}</span></p>}
      {data.mppr4549 !== undefined && <p><FormattedMessage id="demo.full.mppr4549" /><span>{data.mppr4549}</span></p>}
      {data.mppr5054 !== undefined && <p><FormattedMessage id="demo.full.mppr5054" /><span>{data.mppr5054}</span></p>}
      {data.mppr5559 !== undefined && <p><FormattedMessage id="demo.full.mppr5559" /><span>{data.mppr5559}</span></p>}
      {data.mppr6064 !== undefined && <p><FormattedMessage id="demo.full.mppr6064" /><span>{data.mppr6064}</span></p>}
      {data.mppr6569 !== undefined && <p><FormattedMessage id="demo.full.mppr6569" /><span>{data.mppr6569}</span></p>}
      {data.mppr7074 !== undefined && <p><FormattedMessage id="demo.full.mppr7074" /><span>{data.mppr7074}</span></p>}
      {data.mppr7599 !== undefined && <p><FormattedMessage id="demo.full.mppr7599" /><span>{data.mppr7599}</span></p>}
    </div>
  )
}