import React from "react"
import styles from "./headerMobile.module.css"
import { getLocalizedPath } from "../../i18n";
import { PageContext } from "../../pageContext";
import PropTypes from "prop-types"
import logo_dark from '../../images/logos/logo_dark.svg'
import logo_white from '../../images/logos/logo_white.svg'
import right from '../../images/icons/right-arrow.svg'
import left from '../../images/icons/left-arrow.svg'
import close from '../../images/icons/cancel.svg'
import hamburger from '../../images/icons/menu-button.svg'
import hamburger_light from '../../images/icons/menu-button-light.svg'
import { Link } from "gatsby"
import { navigation } from '../../variables/navigation.js'
import { injectIntl } from "react-intl";
import { compose } from "recompose";

const ulStyle = (level, n, nav, label) => {
  if (level === 1 && n === 0) {
    return { transform: 'translate(-100%)' }
  } else if (level === 2 && n === 0) {
    return { transform: 'translate(-200%)' }
  } else if (nav === label) {
    return { zIndex: 999999, visibility: 'visible' }
  }
}

const renderList = (array, n, pathname, level, levelUp, levelDown, nav, label, handleClose, originalPath) => {
  return (
    <ul className={styles.navigation} style={ulStyle(level, n, nav, label)}>
      {array.map((item, i) => {
        return (
          <li key={i} className={styles.navigationItem}>
            {item.link ? (
              <>
                {pathname && pathname.replace(/\//g, '') === item.link.split('/')[0] && n === 2 ? (
                  <div>
                    <a href={"/" + item.link} className={styles.link} onClick={handleClose}>
                      {item.label}
                    </a>
                    {item.children ? <img src={right} alt="" onClick={() => levelUp(item.label)} /> : ''}
                    {item.badge ? <a href={"/" + item.link} className={styles.badge} onClick={handleClose}>demo</a> : ``}
                  </div>
                ) : (
                    <div>
                      {(item.label === 'Polski' || item.label === 'English') ? (
                        <Link to={getLocalizedPath(originalPath, item.link)} className={styles.link} onClick={handleClose}>
                          {item.label}
                        </Link>
                      ) : (
                          <Link to={"/" + item.link + "/"} className={styles.link} onClick={handleClose}>
                            {item.label}
                          </Link>
                        )}
                      {item.children ? <img src={right} alt="" onClick={() => levelUp(item.label)} /> : ''}
                      {item.badge ? <Link to={"/" + item.link + "/"} className={styles.badge} onClick={handleClose}>demo</Link> : ``}
                    </div>
                  )}
              </>
            ) : (
                <div>
                  <span className={styles.link + ' ' + styles.withChildren} onClick={() => levelUp(item.label)}>
                    {item.label}
                  </span>
                  <img src={right} alt="" onClick={() => levelUp(item.label)} />
                </div>
              )}
            {item.children ? (
              renderList(item.children, n === 1 ? 2 : 1, pathname, level, levelUp, levelDown, nav, item.label, handleClose, originalPath)
            ) : ''}
          </li>
        )
      })}
    </ul>
  )
}


const classnames = (transparent, isFirst) => {
  if (transparent) {
    if (!isFirst) {
      return styles.headerContainer + ' ' + styles.transparent + ' ' + styles.sticky
    } else {
      return styles.headerContainer + ' ' + styles.transparent
    }
  } else {
    return styles.headerContainer
  }
}


class HeaderMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      level: 0,
      nav: 'parent',
      opened: false
    };
  }

  handleBack = (event) => {
    if (this.state) {
      if (this.state.opened) {
        this.setState({ opened: false })
      }
    }
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handleBack)
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleBack);
  }

  levelUp = (id) => {
    this.setState({
      level: this.state.level + 1,
      nav: id
    })
  }

  levelDown = () => {
    const { level } = this.state
    if (level !== 0) {
      this.setState({
        level: level - 1,
        nav: 'Produkty'
      })
    }
  }

  handleClose = (e) => {
    this.setState({
      level: 0,
      nav: 'parent',
      opened: false
    })
  }

  handleOpen = () => {
    this.setState({
      opened: true
    })
  }

  render() {
    const { pathname, isFirst, transparent, intl } = this.props
    const { opened, level, nav } = this.state
    return (
      <PageContext.Consumer>
        {({ originalPath, locale }) => (
          <div className={classnames(transparent, isFirst)}>
            {isFirst ?
              <div className={styles.mobileNav}>
                <img src={hamburger_light} alt="" className={styles.menuIcon} onClick={this.handleOpen} />
                <Link to={intl.formatMessage({ id: 'nav.home' })}><img src={logo_white} alt="Locit Logo" className={styles.logo} /></Link>
              </div>
              :
              <div className={styles.mobileNav}>
                <img src={hamburger} alt="" className={styles.menuIcon} onClick={this.handleOpen} />
                <Link to={"/"}><img src={logo_dark} alt="Locit Logo" className={styles.logo} /></Link>
              </div>
            }
            <header className={opened ? styles.header + ' ' + styles.opened : styles.header}>
              <div className="row">
                <div className={styles.headerCol + ' col-12 center'}>
                  <div className={styles.navHeader} style={level !== 0 ? { justifyContent: 'space-between' } : { justifyContent: 'flex-end' }}>
                    {level !== 0 ? <img src={left} alt="" onClick={this.levelDown} /> : ''}
                    <img src={close} alt="" onClick={this.handleClose} />
                  </div>
                  {renderList(navigation(intl), 0, pathname, level, this.levelUp, this.levelDown, nav, '', this.handleClose, originalPath)}
                  <div className={styles.logoNav}>
                  </div>
                </div>
              </div>
            </header>
          </div >
        )}
      </PageContext.Consumer>
    )
  }
}

HeaderMobile.propTypes = {
  transparent: PropTypes.bool,
  pathname: PropTypes.string,
  isFirst: PropTypes.bool
}

HeaderMobile.defaultProps = {
  transparent: false,
  pathname: ``,
  isFirst: false
}

export default compose(
  injectIntl
)(HeaderMobile)