export const navigation = (intl) => [
  // {
  //   label: intl.formatMessage({ id: 'nav.bottom.o_nas' }),
  //   link: intl.formatMessage({ id: 'nav.bottom.o_nas.slug' })
  // },
  {
    label: intl.formatMessage({ id: 'nav.kontakt' }),
    link: intl.formatMessage({ id: 'nav.kontakt.slug' })
  },
  // {
  //   label: intl.formatMessage({ id: 'nav.bottom.warunki' }),
  //   link: intl.formatMessage({ id: 'nav.bottom.warunki.slug' })
  // },
  {
    label: intl.formatMessage({ id: 'nav.bottom.polityka' }),
    link: intl.formatMessage({ id: 'nav.bottom.polityka.slug' })
  }
]