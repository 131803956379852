import React from "react"
import styles from "./footer.module.css"
import datawise from '../../images/logos/datawise.svg'
import logo_white from '../../images/logos/logo_white.svg'
import { Link } from "gatsby"
import { compose } from "recompose";
import { injectIntl } from "react-intl";
import { navigation } from '../../variables/navigation_bottom.js'

const isMobile = typeof window !== `undefined` && window.innerWidth <= 768;

const Footer = (props) => (
  <div className={styles.footer + ' section fp-auto-height'}>
    <div className="row">
      <div className="col-8">
        <ul className={styles.footerNav}>
          {navigation(props.intl).map((item, i) => {
            return <li key={i}><Link className={styles.footerLink} to={'/' + item.link}>{item.label}</Link></li>
          })}
        </ul>
      </div>
      <div className="col-4">
        <div className={styles.flex}>
          <span>{props.intl.formatMessage({ id: 'footer.api' })}</span>
          {/* <ImageSmall filename="datawise.png" /> */}
          <img src={datawise} alt="DataWise logo" />
        </div>
      </div>
    </div>
    {isMobile ? (
      <div className="row">
        <div className="col-12">
          <Link to={props.intl.formatMessage({ id: 'nav.home' })}><img src={logo_white} alt="Locit Logo" className={styles.logo} /></Link>
        </div>
      </div>
    ) : ''}
  </div>
)

export default compose(
  injectIntl
)(Footer)