import React, { Component } from 'react'
import styles from "./optionSwitch.module.css"
import PropTypes from "prop-types"

class OptionSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: 0
    };
  }

  toggleOptions = () => {
    const { toggle } = this.props
    this.state.option === 0 ? this.setState({ option: 1 }) : this.setState({ option: 0 })
    toggle()
  }

  render () {
    const { first, second } = this.props;
    const { option } = this.state;
    return (
      <div className={styles.optionSwitch}>
        <span>{first}</span>
        <div className={styles.switch} onClick={this.toggleOptions}>
          <span className={option === 1 ? styles.switchInner + ' ' + styles.right : styles.switchInner}></span>
        </div>
        <span>{second}</span>
      </div >
    )
  }
}

OptionSwitch.propTypes = {
  toggle: PropTypes.func,
}

OptionSwitch.defaultProps = {
  toggle: () => { },
}

export default OptionSwitch