import React from "react"
import styles from "./button.module.css"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const classnames = (type) => {
  switch (type) {
    case 'blue':
      return styles.button + ' ' + styles.blue
    case 'yellow':
      return styles.button + ' ' + styles.yellow
    case 'green':
      return styles.button + ' ' + styles.green
    case 'transparent':
      return styles.button + ' ' + styles.transparent
    case 'white':
      return styles.button + ' ' + styles.white
    default:
      return styles.button + ' ' + styles.blue
  }
}

const Button = ({ link, text, type, target }) => (
  target === "blank" ? (
    <a href={link} className={classnames(type)} target="_blank" rel="noopener noreferrer">{text}</a>
  ) : (
      <Link className={classnames(type)} to={link}>{text}</Link>
    )
)

Button.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  target: PropTypes.string
}

Button.defaultProps = {
  link: "/",
  text: ``,
  type: 'blue',
  target: ``
}

export default Button
