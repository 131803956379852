import React, { Component } from 'react'
import styles from "./demoDevAddressHygiene.module.css"
import Button from '../../../Button/button';
import ActionButton from '../../../Button/actionButton';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import arta from 'react-syntax-highlighter/dist/esm/styles/hljs/arta';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
SyntaxHighlighter.registerLanguage('json', json);

const isMobile = typeof window !== `undefined` && window.innerWidth <= 768;

class DemoDevGeocoding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zip: '',
      city: '',
      street: '',
      building: '',
      country: '',
      curl: 'curl -X POST "http://locit.eu/webservice/geocoding-here/v2.2.0/" -H "accept: application/json" -H "Content-Type: application/x-www-form-urlencoded" -d "country=GBR&zip=SW1A%202AA&city=London&street=Downing%20St&building=10&format=json&charset=UTF-8"',
      code: {
        "info": {
          "code": 200,
          "message": "OK response. Server understood request and returned correct response",
          "machine": "prod1",
          "version": "v2.2.0"
        },
        "data": {
          "label": "10 Downing Street, London, SW1A 2, United Kingdom",
          "country": "GBR",
          "country_name": "United Kingdom",
          "state": "England",
          "county": "London",
          "city": "London",
          "district": "Westminster",
          "street": "Downing Street",
          "building": "10",
          "postal_code": "SW1A 2",
          "y": "51.50341",
          "x": "-0.12765",
          "location_id": "NT_lWsc8knsFwVitNTFX88zmA_xAD",
          "location_type": "address",
          "match_level": "houseNumber",
          "match_type": "pointAddress",
          "status": "200",
          "message": "Address recognised and geocoded"
        }
      }
    }
  }

  handleChange = (e) => {
    const name = e.target.name
    switch (name) {
      case 'locit-zip':
        this.setState({ zip: e.target.value })
        return false;
      case 'locit-city':
        this.setState({ city: e.target.value })
        return false;
      case 'locit-street':
        this.setState({ street: e.target.value })
        return false;
      case 'locit-building':
        this.setState({ building: e.target.value })
        return false;
      case 'locit-country':
        this.setState({ country: e.target.value })
        return false;
      default:
        this.setState({ zip: e.target.value })
        return false;
    }
  }

  clearInputs = () => {
    this.setState({
      zip: '',
      city: '',
      street: '',
      building: '',
      country: '',
    })
  }

  getData = () => {
    const { zip, city, street, building, country } = this.state;
    this.fetchAddress(zip, city, street, building, country)
  }

  fetchAddress = (zip, city, street, building, country) => {
    const url = 'https://api.locit.dev.beecommerce.pl/geocoding';
    fetch(url, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        zip: zip,
        city: city,
        street: street,
        building: building,
        country: country
      })
    })
      .then(response => response.json())
      .then(response => {
        const query = 'zip=' + zip + '&city=' + city + '&street=' + street + '&building=' + building + '&country=' + country + '&format=json&charset=UTF-8';
        const query_slug = query.replace(/ /g, '%20')
        this.setState({
          curl: 'curl -X POST "http://locit.eu/webservice/geocoding-here/v2.2.0/" -H "accept: application/json" -H "Content-Type: application/x-www-form-urlencoded" -d "' + query_slug + '"',
          code: response
        })
      })
  }

  render () {
    const { intl } = this.props
    const { zip, city, street, building, country, code, curl } = this.state
    return (
      <>
        <div className={styles.devDemo + ' row'}>
          <div className={'col-8 ' + styles.inputs}>
            <div className="row">
              <div className={isMobile ? "col-2 m0 " + styles.label : "col-2"}>
                <span>{intl.formatMessage({ id: 'demo2.dev.zip_label' })}</span>
              </div>
              <div className={isMobile ? "col-10 m0" : "col-10 mb0"}>
                <label htmlFor="locit-zip">
                  {intl.formatMessage({ id: 'demo2.dev.zip_desc' })}
                  <input type="text" name="locit-zip" value={zip} onChange={this.handleChange} autoComplete="off" />
                </label>
              </div>
            </div>
            <div className="row">
              <div className={isMobile ? "col-2 m0 " + styles.label : "col-2"}>
                <span>{intl.formatMessage({ id: 'demo2.dev.city_label' })}</span>
              </div>
              <div className={isMobile ? "col-10 m0" : "col-10 mb0"}>
                <label htmlFor="locit-city">
                  {intl.formatMessage({ id: 'demo2.dev.city_desc' })}
                  <input type="text" name="locit-city" value={city} onChange={this.handleChange} autoComplete="off" />
                </label>
              </div>
            </div>
            <div className="row">
              <div className={isMobile ? "col-2 m0 " + styles.label : "col-2"}>
                <span>{intl.formatMessage({ id: 'demo2.dev.street_label' })}</span>
              </div>
              <div className={isMobile ? "col-10 m0" : "col-10 mb0"}>
                <label htmlFor="locit-street">
                  {intl.formatMessage({ id: 'demo2.dev.street_desc' })}
                  <input type="text" name="locit-street" value={street} onChange={this.handleChange} autoComplete="off" />
                </label>
              </div>
            </div>
            <div className="row">
              <div className={isMobile ? "col-2 m0 " + styles.label : "col-2"}>
                <span>{intl.formatMessage({ id: 'demo2.dev.building_label' })}</span>
              </div>
              <div className={isMobile ? "col-10 m0" : "col-10 mb0"}>
                <label htmlFor="locit-building">
                  {intl.formatMessage({ id: 'demo2.dev.building_desc' })}
                  <input type="text" name="locit-building" value={building} onChange={this.handleChange} autoComplete="off" />
                </label>
              </div>
            </div>
            <div className="row">
              <div className={isMobile ? "col-2 m0 " + styles.label : "col-2"}>
                <span>{intl.formatMessage({ id: 'demo2.dev.country_label' })}</span>
              </div>
              <div className={isMobile ? "col-10 m0" : "col-10 mb0"}>
                <label htmlFor="locit-country">
                  {intl.formatMessage({ id: 'demo2.dev.country_desc' })}
                  <input type="text" name="locit-country" value={country} onChange={this.handleChange} autoComplete="off" />
                </label>
              </div>
            </div>
          </div>
          <div className={styles.buttons + ' col-5'}>
            {!isMobile ? <Button text={intl.formatMessage({ id: 'demo.dev.swagger' })} type="green" link="https://api.locit.io/docs/?urls.primaryName=geocoding-v2.2.0" target="blank" /> : ''}
            <div className={styles.buttonsContainer}>
              <ActionButton text={intl.formatMessage({ id: 'button.send' })} type="blue" action={this.getData} />
              <ActionButton text={intl.formatMessage({ id: 'button.clear' })} type="transparent" action={this.clearInputs} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className={styles.responses}>{intl.formatMessage({ id: 'demo.dev.responses' })}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-1">
            <span>Curl</span>
          </div>
          <div className="col-11">
            <div className={styles.code}>
              <SyntaxHighlighter language="json" style={arta} customStyle={{ color: '#fff', padding: 15 }}>
                {curl}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-1">
            <span>{intl.formatMessage({ id: 'demo.dev.code' })}</span>
          </div>
          <div className="col-11">
            <div className={styles.code}>
              <SyntaxHighlighter language="json" style={arta} customStyle={{ color: '#fff', padding: 15 }}>
                {JSON.stringify(code, null, 2)}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>
        {isMobile ? <div className="row">
          <div className="col-12"> <Button text={intl.formatMessage({ id: 'demo.dev.swagger' })} type="green" link="https://api.locit.io/docs/?urls.primaryName=geocoding-v2.2.0" target="blank" /></div>
        </div> : ''}
      </>
    )
  }
}


export default compose(
  injectIntl
)(DemoDevGeocoding)
