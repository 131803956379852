/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/isStatic-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "../Header/header"
import HeaderMobile from "../HeaderMobile/headerMobile"
import { StaticQuery, graphql } from "gatsby"
import "../layout.css"
import "../../styles/grid.css"

const isDesktop = typeof window !== `undefined` && window.innerWidth > 768;

const Layout = ({ location, isFirst, children, isStatic, pathname }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        {isDesktop ?
          <Header transparent={location === '/' ? true : false} isFirst={isFirst} pathname={pathname} />
          :
          <HeaderMobile transparent={location === '/' ? true : false} isFirst={isFirst} pathname={pathname} />
        }
        <div>
          <main className={isStatic ? 'static-page' : ''}>{children}</main>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.string,
  isFirst: PropTypes.bool,
  isStatic: PropTypes.bool,
  pathname: PropTypes.string
}

Layout.defaultProps = {
  location: ``,
  isFirst: false,
  isStatic: false,
  pathname: ``
}

export default Layout