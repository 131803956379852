import React from "react"
import styles from './section.module.css'
import PropTypes from "prop-types"


const Section = ({ children, color, textColor }) => (
  <div className="section" style={{ backgroundColor: color, color: textColor }}>
    <div className={styles.container}>
      {children}
    </div>
  </div>
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  textColor: PropTypes.string
}

Section.defaultProps = {
  color: '#fff',
  textColor: "#000"
}

export default Section
