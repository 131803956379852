import React from "react"
import LazyLoad from 'react-lazy-load';
import styles from "./featureSection.module.css"
import PropTypes from "prop-types"
import BackgroundSection from '../BackgroundSection/backgroundSection'
// import ImageLarge from '../Image/imageLarge'
import ActionButton from '../Button/actionButton'
import Button from '../Button/button'

const isDesktop = typeof window !== `undefined` && window.innerWidth > 768
const isMobile = typeof window !== `undefined` && window.innerWidth <= 768;

const textPart = (title, text, link, action, type, buttonText, target) => {
  return <div className="col-6">
    <h2 className={styles.featureTitle}>{title}</h2>
    <p className={styles.featureText}>{text}</p>
    {!link ? <ActionButton text={buttonText} action={action} type={type} /> : <Button text={buttonText} link={link} type={type} target={target} />}
  </div>
}

const imagePart = (img, text) => {
  return <div className={isDesktop ? "col-6 " + styles.imgSection : "col-6"}>
    {/* <ImageLarge filename={img} /> */}
    <LazyLoad>
      <img src={img} alt={text} width="600" className={styles.featureImage} />
    </LazyLoad>
  </div>
}

const position = (imageRight) => {
  if (isMobile) {
    if (imageRight) {
      return '0 50px'
    } else {
      return '0 10px'
    }
  } else {
    if (imageRight) {
      return '100% 10vh'
    } else {
      return '0 0'
    }
  }
}

const FeatureSection = ({ imageRight, img, title, text, link, action, background, type, buttonText, target }) => (
  <BackgroundSection color="#fff" textColor="#2D2D2D" filename={background} size="contain" position={position(imageRight)}>
    <div className={styles.flex + " row"}>
      {imageRight && isDesktop ?
        <>
          {textPart(title, text, link, action, type, buttonText, target)}
          {/* {gif ? <img src={img} alt={text} width="600" className={styles.gif} /> : imagePart(img)} */}
          {imagePart(img, text)}
        </>
        :
        <>
          {imagePart(img, text)}
          {/* {gif ? <img src={img} alt={text} width="600" className={styles.gif} /> : imagePart(img)} */}
          {textPart(title, text, link, action, type, buttonText, target)}
        </>
      }
    </div>
  </BackgroundSection>
)

FeatureSection.propTypes = {
  imageRight: PropTypes.bool,
  img: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  action: PropTypes.func,
  background: PropTypes.string,
  type: PropTypes.string,
  buttonText: PropTypes.string,
  target: PropTypes.string
}

FeatureSection.defaultProps = {
  imageRight: true,
  img: ``,
  title: ``,
  text: ``,
  link: ``,
  action: () => { },
  background: '#fff',
  type: 'blue',
  buttonText: ``,
  target: ``
}

export default FeatureSection
