module.exports = [
    {
        default: true,
        locale: "pl",
        label: "Polski",
        routes: {
            "/": "/",
            "/adresy": "/adresy",
            "/geokodowanie": "/geokodowanie",
            "/obliczenia-geograficzne": "/obliczenia-geograficzne",
            "/mapy": "/mapy",
            "/dane-statystyczne": "/dane-statystyczne",
            "/404": "/404",
            "/kontakt": "/kontakt",
            "/polityka-prywatnosci": "/polityka-prywatnosci",
            "/cennik": "/cennik",
            "/dlaczego-my": "/dlaczego-my",
            "/saas-on-premise": "/saas-on-premise",
            "/404.html": "/404.html",
            "/offline-plugin-app-shell-fallback": "/offline-plugin-app-shell-fallback",
            // "/example-static-page": "/example-static-page"
        }
    },
    {
        locale: "en",
        label: "English",
        routes: {
            "/": "/",
            "/adresy": "/customer-data-quality",
            "/geokodowanie": "/geocoding",
            "/obliczenia-geograficzne": "/spatial-calculations",
            "/mapy": "/maps",
            "/dane-statystyczne": "/statistical-data",
            "/404": "/404",
            "/kontakt": "/contact",
            "/polityka-prywatnosci": "/privacy-policy",
            "/cennik": "/pricing",
            "/404.html": "/404.html",
            "/dlaczego-my": "/why-us",
            "/saas-on-premise": "/saas-on-premise",
            "/offline-plugin-app-shell-fallback": "/offline-plugin-app-shell-fallback",
            // "/example-static-page": "/example-static-page-en"
        }
    }
];
