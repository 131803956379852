import React from 'react'
import styles from "./demoAddressAutocomplete.module.css"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { Link } from 'gatsby';
import { fullDataBlock } from './fullData'
import { fullDataBuildingBlock } from './fullDataBuilding'
import marker from '../../../../images/icons/marker_icon.svg'
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';

const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
const isMobile = typeof window !== `undefined` && window.innerWidth <= 768;

const MapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={props.building_info ? 17 : 6}
    defaultCenter={props.position}
    center={props.position}
    zoom={props.building_info ? 17 : 6}
    defaultOptions={{ mapTypeControl: false, streetViewControl: false, zoomControl: false }}
  >
    {typeof window !== `undefined` && props.building_info && <InfoBox
      defaultPosition={new window.google.maps.LatLng(props.position.lat, props.position.lng)}
      options={{ closeBoxURL: ``, enableEventPropagation: true, maxWidth: isMobile ? 180 : 200 }}
      position={new window.google.maps.LatLng(props.position.lat, props.position.lng)}
    >
      <div style={{ backgroundColor: `white`, padding: 15, borderLeft: '2px solid #304FFE', minWidth: isMobile ? 180 : 250, maxWidth: isMobile ? 180 : 250 }}>
        <div style={{ fontSize: isMobile ? 12 : 14, fontFamily: 'Montserrat' }}>
          <span style={{ color: '#304FFE', display: 'block', marginBottom: 5 }}>{props.building_info.add1}</span>
          <span style={{ color: '#304FFE', display: 'block', marginBottom: 5 }}>{props.building_info.add2}</span>
          <span style={{ display: 'block', marginBottom: 5 }}>{props.intl.formatMessage({ id: 'demo1.map.count' })}: {props.building_info.locals}</span>
          {props.building_info.year ? <span style={{ display: 'block' }}>{props.intl.formatMessage({ id: 'demo1.map.year' })}: {props.building_info.year}</span> : ``}
          {props.version !== 1 ? (
            <span style={{ display: 'inline-block', maxWidth: 240, marginTop: 5 }}>
              <Link to='/dane-statystyczne/#punkty-adresowe' style={{ color: 'inherit' }}>{props.intl.formatMessage({ id: 'demo1.map.see_more' })}</Link>
            </span>
          ) : ``}
        </div>
      </div>
    </InfoBox>}
    {props.isMarkerShown && <Marker position={props.position} options={{ icon: marker }} />}
  </GoogleMap>
))

class DemoAddressAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullData: false,
      input: '',
      address: {},
      building_info: {},
      position: {
        lat: 51.9984334,
        lng: 18.9926065
      },
      suggestions: [],
      hiddenSuggestions: false
    };
  }

  fetchAddress = (input) => {
    const url = 'https://api.locit.dev.beecommerce.pl/autocomplete/full/' + input;
    fetch(url)
      .then(response => response.json())
      .then(response => {
        const data = response.data
        this.setState({
          suggestions: data
        })
      })
  }

  fetchBuildingInfo = (id) => {
    const url = 'https://api.locit.dev.beecommerce.pl/building_info/' + id;
    fetch(url)
      .then(response => response.json())
      .then(response => {
        const data = response.data
        this.setState({
          building_info: data
        })
      })
  }

  toggleFullData = () => {
    const currentFullData = this.state.fullData
    this.setState({ fullData: !currentFullData })
  }

  handleChange = (e) => {
    this.setState({
      input: e.target.value,
      hiddenSuggestions: false
    })
  }

  setData = (item) => {
    this.setState({
      address: item,
      input: item.city + ' ' + item.prefix + ' ' + item.street + ' ' + item.building,
      position: {
        lat: parseFloat(item.y),
        lng: parseFloat(item.x)
      },
      suggestions: [],
      hiddenSuggestions: true
    })
    this.fetchBuildingInfo(item.uuid)
  }

  componentDidUpdate (prevProps, prevState) {
    const { input, hiddenSuggestions } = this.state
    if (prevState.input !== input && !hiddenSuggestions) {
      if (input.length > 3) {
        this.fetchAddress(input)
      }
    }
  }

  render () {
    const { fullData, input, suggestions, position, address, building_info } = this.state
    const { version, intl } = this.props;
    return (
      <>
        {!isMobile ? (
          <div className="row">
            <div className="col-6">
              <h5 className={styles.demoSubtitle}>{intl.formatMessage({ id: 'demo1.test' })}</h5>
            </div>
            <div className="col-6 right">
              <span className={(fullData ? styles.opened + ' ' : '') + styles.fullDataSwitch} onClick={this.toggleFullData}>{intl.formatMessage({ id: 'demo.full' })}</span>
            </div>
          </div>
        ) : ''}
        <div className={styles.demo + ' row'}>
          <div className="col-5">
            <label htmlFor="locit-address">
              {intl.formatMessage({ id: 'demo.begin' })}
              <input
                type="text"
                name="locit-address"
                value={input}
                onChange={this.handleChange}
                placeholder={intl.formatMessage({ id: 'demo.begin' })}
                autoComplete="off"
              />
            </label>
            {suggestions.length > 0 && input.length > 3 ? (
              <div className={styles.addressSuggestions}>
                <ul>
                  {suggestions.map((item, i) => {
                    return (i < 20) && (
                      <li key={i} onClick={() => this.setData(item)}>
                        <span>{item.city + ' ' + item.prefix + ' ' + item.street + ' ' + item.building}</span>
                        <small>{item.voiv + ' / ' + item.pov + ' / ' + item.mun + ' / uuid: ' + item.uuid}</small>
                      </li>
                    )
                  })}
                </ul>
              </div>
            ) : ''}
            <span className={styles.gap}></span>
            <h5 className={styles.demoSubtitle}>{intl.formatMessage({ id: 'demo1.standarized' })}</h5>
            <label htmlFor="locit-street">
              {intl.formatMessage({ id: 'demo1.street' })}
              <input
                type="text"
                name="locit-street"
                disabled
                value={address.street ? address.prefix + ' ' + address.street : ''}
                autoComplete="off"
              />
            </label>
            <label htmlFor="locit-building">
              {intl.formatMessage({ id: 'demo1.building' })}
              <input
                type="text"
                name="locit-building"
                disabled
                value={address.building ? address.building : ''}
                autoComplete="off"
              />
            </label>
            <label htmlFor="locit-zip">
              {intl.formatMessage({ id: 'demo1.zip' })}
              <input
                type="text"
                name="locit-zip"
                disabled
                value={address.zip ? address.zip : ''}
                autoComplete="off"
              />
            </label>
            <label htmlFor="locit-city">
              {intl.formatMessage({ id: 'demo1.city' })}
              <input
                type="text"
                name="locit-city"
                disabled
                value={address.city ? address.city : ''}
                autoComplete="off"
              />
            </label>
          </div>
          <div className={styles.dataContainer + " col-7"}>
            {!isMobile & fullData ? (
              version === 1 ? (
                <div className={styles.fullData}>{fullDataBuildingBlock(building_info)}</div>
              ) : (
                  <div className={styles.fullData}>{fullDataBlock(address)}</div>
                )
            ) : ''}
            <div className={styles.map}>
              <MapComponent
                isMarkerShown={address.street ? true : false}
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyD8paCP8rBS7QNXIRgfXmaULvEqRIhcg1s"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: isMobile ? 300 : 430 }} />}
                mapElement={<div style={{ height: `100%` }} />}
                position={position}
                version={version}
                intl={intl}
                building_info={address.street ? (
                  {
                    add1: address.prefix + ' ' + address.street + ' ' + address.building,
                    add2: address.zip + ' ' + address.city,
                    locals: building_info.apts_t,
                    year: building_info.sfp_class
                  }
                ) :
                  ''}
              />
            </div>
          </div>
          {isMobile ? (
            <div className="row">
              <div className="col-12">
                <span className={(fullData ? styles.opened + ' ' : '') + styles.fullDataSwitch} onClick={this.toggleFullData}>{intl.formatMessage({ id: 'demo.full' })}</span>
              </div>
            </div>
          ) : ''}
          {isMobile & fullData ? (
            version === 1 ? (
              <div className={styles.fullData}>{fullDataBuildingBlock(building_info)}</div>
            ) : (
                <div className={styles.fullData}>{fullDataBlock(address)}</div>
              )
          ) : ''}
        </div>
      </>
    )
  }
}

export default compose(
  injectIntl
)(DemoAddressAutocomplete)