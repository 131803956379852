import React from "react"
import styles from './pageBanner.module.css'
import PropTypes from "prop-types"
import GradientSection from '../GradientSection/gradientSection'
import Features from "../Features/features";

const PageBanner = ({ title, desc, features }) => (
  <>
    <GradientSection
      colorLeft="#3D50F9"
      colorRight="#250B63"
      textColor="#fff">
      <div className="row">
        {features ? (
          <>
            <div className="col-6">
              <h1 className={desc ? styles.heading : styles.heading + ' ' + styles.noDesc}>{title}</h1>
              {desc ? <p className={styles.desc}>{desc}</p> : ""}
            </div>
            {features ? <div className="col-6"><Features items={features} section={false} small={true} /></div> : ''}
          </>
        ) : (
            <div className="col-12">
              <h1 className={styles.heading}>{title}</h1>
              {desc ? <p className={styles.desc}>{desc}</p> : ""}
            </div>
          )}
      </div>
    </GradientSection >
  </>
)

PageBanner.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  features: PropTypes.array
}

PageBanner.defaultProps = {
  title: ``,
  desc: ``,
  features: []
}

export default PageBanner
