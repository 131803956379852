import React from 'react'
import styles from "./demoCatchmentData.module.css"
import { compose, lifecycle } from 'recompose'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polygon } from "react-google-maps"
import { fullDataBlock } from './fullData'
import { fullDataAreaBlock } from './fullDataArea'
import { Link } from 'gatsby';
import ActionButton from '../../../Button/actionButton';
import auto from '../../../../images/icons/auto.svg'
import pieszy from '../../../../images/icons/pieszy.svg'
import okrag from '../../../../images/icons/okrag.svg'
import marker from '../../../../images/icons/marker.svg'
import Slider from 'rc-slider/lib/Slider';
import 'rc-slider/assets/index.css';
import { injectIntl } from 'react-intl';

const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
const isMobile = typeof window !== `undefined` && window.innerWidth <= 768;

const MapComponent = compose(lifecycle({
  componentWillUpdate (nextProps, nextState) {
    if (this.props.paths !== nextProps.paths) {
      const refs = {}

      this.setState({
        setZoom: ref => {
          refs.map = ref
          if (!ref) { return }
          var bounds = new window.google.maps.LatLngBounds();
          nextProps.paths.forEach((p) => {
            var latLng = new window.google.maps.LatLng(p.lat, p.lng);
            bounds.extend(latLng);
          })
          refs.map.fitBounds(bounds)
        }
      })
    }

  }
}), withScriptjs, withGoogleMap)((props) =>
  <GoogleMap
    ref={props.info ? props.setZoom : null}
    defaultZoom={props.info ? 17 : 6}
    defaultCenter={props.position}
    center={props.position}
    zoom={props.info ? 17 : 6}
    defaultOptions={{ mapTypeControl: false, streetViewControl: false, zoomControl: false }}
  >
    {typeof window !== `undefined` && props.info && <InfoBox
      defaultPosition={new window.google.maps.LatLng(props.position.lat, props.position.lng)}
      options={{ closeBoxURL: ``, enableEventPropagation: true, maxWidth: isMobile ? 180 : 200 }}
      position={new window.google.maps.LatLng(props.position.lat, props.position.lng)}
    >
      <div style={{ backgroundColor: `white`, padding: 15, borderLeft: '2px solid #304FFE', minWidth: isMobile ? 180 : 250, maxWidth: isMobile ? 180 : 250 }}>
        <div style={{ fontSize: isMobile ? 12 : 14, fontFamily: 'Montserrat', display: 'flex', justifyContent: 'space-between' }}>
          {props.intl.formatMessage({ id: 'demo3.map.pop' })}: <span style={{ color: '#304FFE', display: 'block', marginBottom: 5 }}>{props.info.pop}</span>
        </div>
        <div style={{ fontSize: isMobile ? 12 : 14, fontFamily: 'Montserrat', display: 'flex', justifyContent: 'space-between' }}>
          {props.intl.formatMessage({ id: 'demo3.map.working' })}: <span style={{ color: '#304FFE', display: 'block', marginBottom: 5 }}>{props.info.workers}</span>
        </div>
        {props.version === 1 ? (
          <span style={{ display: 'inline-block', maxWidth: 240, marginTop: 5, fontSize: 14, fontFamily: 'Montserrat' }}>
            <Link to='/dane-statystyczne/#potencjal-stref-zasiegu' style={{ color: 'inherit' }}>{props.intl.formatMessage({ id: 'demo3.map.more' })}</Link>
          </span>
        ) : ``}
      </div>
    </InfoBox>}

    {props.isMarkerShown && <Marker position={props.position} options={{ icon: marker }} />}
    {props.paths &&
      <Polygon
        paths={props.paths}
        options={{
          strokeColor: "#304FFE",
          fillColor: "#E8EEFB",
          strokeOpacity: "0.5",
          strokeWeight: '2',
          zIndex: 1
        }}
      />
    }
  </GoogleMap>
)

class DemoCatchmentData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullData: false,
      address: '',
      suggestions: [],
      hiddenSuggestions: false,
      method: 'car',
      optimization: 'time',
      rangeDist: 500,
      rangeTime: 10,
      data: {},
      isoline: {},
      points: [],
      position: {
        lat: 51.9984334,
        lng: 18.9926065
      },
      mapPosition: {
        lat: 51.9984334,
        lng: 18.9926065
      },
      days: 'wed',
      time: '13:00'
    };
  }

  fetchData = () => {
    const { optimization, method, rangeDist, rangeTime, position, days, time } = this.state
    const range = optimization === 'time' ? rangeTime * 60 : rangeDist;
    const url = 'https://api.locit.dev.beecommerce.pl/catchment_area/' + optimization + '/' + method + '/' + range + '/' + position.lat + '/' + position.lng + `/${days} ${time}`;
    const encodedURL = encodeURI(url);
    fetch(encodedURL)
      .then(response => response.json())
      .then(response => {
        const data = response.data
        const ewkt = data.isolines[0].ewkt;

        let polylineArr = []
        let polyline = ewkt.split('MULTIPOLYGON')
        polyline = polyline[1].replace(/\(/g, '').replace(/\)/g, '')
        polyline = polyline.split(',')
        polyline.map(item => {
          let coords = item.split(' ')
          polylineArr.push({
            lat: parseFloat(coords[1]),
            lng: parseFloat(coords[0])
          })
          return true;
        })

        this.setState({
          isoline: data.isolines[0],
          points: polylineArr
        })

        const url = 'https://api.locit.dev.beecommerce.pl/catchment_data';
        fetch(url, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            y: position.lat,
            x: position.lng,
            geom: ewkt
          })
        })
          .then(response => response.json())
          .then(response => {
            const data = response.data
            this.setState({
              data, mapPosition: {
                lat: position.lat,
                lng: position.lng
              }
            })
          })
      })
  }

  fetchAddress = (input) => {
    const url = 'https://api.locit.dev.beecommerce.pl/autocomplete/full/' + input;
    fetch(url)
      .then(response => response.json())
      .then(response => {
        const data = response.data
        this.setState({
          suggestions: data
        })
      })
  }

  toggleFullData = () => {
    const currentFullData = this.state.fullData
    this.setState({ fullData: !currentFullData })
  }

  handleChange = (e) => {
    const name = e.target.name
    switch (name) {
      case 'locit-address':
        this.setState({ address: e.target.value, hiddenSuggestions: false })
        if (e.target.value.length > 3 && !this.state.hiddenSuggestions) {
          this.fetchAddress(e.target.value)
        }
        return false;
      case 'method':
        this.setState({ method: e.target.value })
        return false;
      case 'days':
        this.setState({ days: e.target.value })
        return false;
      case 'time':
        this.setState({ time: e.target.value })
        return false;
      case 'optimization':
        this.setState({ optimization: e.target.value })
        return false;
      case 'rangeDist':
        this.setState({ rangeDist: e.target.value })
        return false;
      case 'rangeTime':
        this.setState({ rangeTime: e.target.value })
        return false;
      default:
        return false;
    }
  }

  setData = (item) => {
    this.setState({
      data: item,
      address: item.city + ' ' + item.prefix + ' ' + item.street + ' ' + item.building,
      suggestions: [],
      hiddenSuggestions: true,
      position: {
        lat: parseFloat(item.y),
        lng: parseFloat(item.x)
      },
    })
  }

  clearData = () => {
    this.setState({
      fullData: false,
      address: '',
      data: {},
      hiddenSuggestions: false,
      isoline: {},
      points: [],
      method: 'car',
      optimization: 'time',
      rangeDist: 500,
      rangeTime: 10,
      position: {
        lat: 51.9984334,
        lng: 18.9926065
      },
      mapPosition: {
        lat: 51.9984334,
        lng: 18.9926065
      },
      days: 'wed',
      time: '13:00'
    })
  }

  onSliderDistChange = (value) => {
    this.setState({
      rangeDist: value,
    });
  };

  onSliderTimeChange = (value) => {
    this.setState({
      rangeTime: value,
    });
  };

  render () {
    const { fullData, mapPosition, data, address, isoline, method, optimization, rangeDist, rangeTime, suggestions, points, days, time } = this.state
    const { version, intl } = this.props
    return (
      <>
        {!isMobile ? (
          <div className="row">
            <div className="col-6">
              <h5 className={styles.demoSubtitle}>{intl.formatMessage({ id: 'button.test' })}</h5>
            </div>
            <div className="col-6 right">
              <span className={(fullData ? styles.opened + ' ' : '') + styles.fullDataSwitch} onClick={this.toggleFullData}>{intl.formatMessage({ id: 'demo.full' })}</span>
            </div>
          </div>
        ) : ''}
        <div className={styles.demo + ' row'}>
          <div className="col-5">
            <label htmlFor="locit-address">
              {intl.formatMessage({ id: 'demo.begin' })}
              <input
                type="text"
                name="locit-address"
                onChange={this.handleChange}
                placeholder={intl.formatMessage({ id: 'demo.begin' })}
                value={address}
                autoComplete="off"
              />
            </label>
            {suggestions.length > 0 && address.length > 3 ? (
              <div className={styles.addressSuggestions}>
                <ul>
                  {suggestions.map((item, i) => {
                    return (i < 20) && (
                      <li key={i} onClick={() => this.setData(item)}>
                        <span>{item.city + ' ' + item.prefix + ' ' + item.street + ' ' + item.building}</span>
                        <small>{item.voiv + ' / ' + item.pov + ' / ' + item.mun + ' / uuid: ' + item.uuid}</small>
                      </li>
                    )
                  })}
                </ul>
              </div>
            ) : ''}
            <span className={styles.gap}></span>
            <h5 className={styles.demoSubtitle}>{intl.formatMessage({ id: 'demo3.method' })}</h5>
            <div className={styles.iconRadioGroup}>
              <div>
                <input
                  type="radio"
                  id="method_1"
                  name="method"
                  value="car"
                  className="hidden"
                  onChange={this.handleChange}
                  checked={method === 'car' ? true : false}
                />
                <label htmlFor="method_1">
                  <img src={auto} alt={intl.formatMessage({ id: 'demo3.method.car' })} className={styles.icon} />
                  <span className={styles.iconLabel}>{intl.formatMessage({ id: 'demo3.method.car' })}</span>
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="method_2"
                  name="method"
                  value="pedestrian"
                  className="hidden"
                  onChange={this.handleChange}
                  checked={method === 'pedestrian' ? true : false}
                />
                <label htmlFor="method_2" >
                  <img src={pieszy} alt={intl.formatMessage({ id: 'demo3.method.pedestrian' })} className={styles.icon} />
                  <span className={styles.iconLabel}>{intl.formatMessage({ id: 'demo3.method.pedestrian' })}</span>
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="method_3"
                  name="method"
                  value="bufor;circle"
                  className="hidden"
                  onChange={this.handleChange}
                  checked={method === 'bufor;circle' ? true : false}
                />
                <label htmlFor="method_3" >
                  <img src={okrag} alt={intl.formatMessage({ id: 'demo3.method.circle' })} className={styles.icon} />
                  <span className={styles.iconLabel}>{intl.formatMessage({ id: 'demo3.method.circle' })}</span>
                </label>
              </div>
            </div>
            <span className={styles.gap}></span>
            <h5 className={styles.demoSubtitle}>{intl.formatMessage({ id: 'demo3.optimization' })}</h5>
            <div className={styles.textRadioGroup}>
              <div>
                <input
                  type="radio"
                  id="optimization_1"
                  name="optimization"
                  value="time"
                  onChange={this.handleChange}
                  checked={optimization === 'time' ? true : false}
                />
                <label htmlFor="optimization_1">
                  <span className={styles.textLabel}>{intl.formatMessage({ id: 'demo3.optimization.time' })}</span>
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="optimization_2"
                  name="optimization"
                  value="distance"
                  onChange={this.handleChange}
                  checked={optimization === 'distance' ? true : false}
                />
                <label htmlFor="optimization_2" >
                  <span className={styles.textLabel}>{intl.formatMessage({ id: 'demo3.optimization.distance' })}</span>
                </label>
              </div>
            </div>
            <span className={styles.gap}></span>
            <h5 className={styles.demoSubtitle}>{intl.formatMessage({ id: 'demo3.range' })}</h5>
            <div className={styles.rangeSlider}>
              <span>{intl.formatMessage({ id: 'demo3.choose_range' })}</span>
              {optimization === 'time' ? (
                <div className={styles.slider}>
                  <Slider
                    value={rangeTime}
                    onChange={this.onSliderTimeChange}
                    onAfterChange={this.onAfterChange}
                    trackStyle={{ backgroundColor: '#304ffe' }}
                    handleStyle={{ borderColor: '#304ffe' }}
                    max={60}
                  />
                  <div className={styles.rangeInput}>
                    <input type="number" name="range" onChange={this.handleChange} value={rangeTime} className={styles.rangeInputField} autoComplete="off" />
                    <span>min</span>
                  </div>
                </div>
              ) : (
                  <div className={styles.slider}>
                    <Slider
                      value={rangeDist}
                      onChange={this.onSliderDistChange}
                      onAfterChange={this.onAfterChange}
                      trackStyle={{ backgroundColor: '#304ffe' }}
                      handleStyle={{ borderColor: '#304ffe' }}
                      max={40000}
                    />
                    <div className={styles.rangeInput}>
                      <input type="number" name="range" onChange={this.handleChange} value={rangeDist} className={styles.rangeInputField} autoComplete="off" />
                      <span>m</span>
                    </div>
                  </div>
                )}
            </div>
            <div className={styles.daysWrapper}>
              <div>
                <label htmlFor="day-selector" >
                  <h5 className={styles.demoSubtitle}>{intl.formatMessage({ id: 'demo3.days.label' })}</h5>
                </label>
                <select  id="day-selector" className={styles.select} name="days"  onChange={this.handleChange} value={days}>
                  <option value="mon">{intl.formatMessage({ id: 'demo3.days.mon' })}</option>
                  <option value="tue">{intl.formatMessage({ id: 'demo3.days.tue' })}</option>
                  <option value="wed">{intl.formatMessage({ id: 'demo3.days.wed' })}</option>
                  <option value="thu">{intl.formatMessage({ id: 'demo3.days.thu' })}</option>
                  <option value="fri">{intl.formatMessage({ id: 'demo3.days.fri' })}</option>
                  <option value="sat">{intl.formatMessage({ id: 'demo3.days.sat' })}</option>
                  <option value="sun">{intl.formatMessage({ id: 'demo3.days.sun' })}</option>
                </select>
              </div>
              <div>
                <label htmlFor="day-time" >
                  <h5 className={styles.demoSubtitle}>{intl.formatMessage({ id: 'demo3.time.label' })}</h5>
                </label>
                <input
                  type="time"
                  id="day-time"
                  min="00:00"
                  max="24:00"
                  placeholder="13:00"
                  name="time"
                  className={styles.time}
                  onChange={this.handleChange}
                  value={time}
                />
              </div>
            </div>
            <div className={styles.buttons}>
              <ActionButton text={intl.formatMessage({ id: 'button.clear' })} type="transparent" noMargin action={this.clearData} />
              <ActionButton text={intl.formatMessage({ id: 'button.send' })} type="blue" noMargin action={() => this.fetchData(address, method)} />
            </div>
          </div>
          <div className={styles.dataContainer + " col-7"}>
            {!isMobile & fullData ? (
              version === 1 ? (
                <div className={styles.fullData}>{fullDataAreaBlock(isoline)}</div>
              ) : (
                  <div className={styles.fullData}>{fullDataBlock(data)}</div>
                )
            ) : ''}
            <div className={styles.map}>
              <MapComponent
                isMarkerShown={isoline.ewkt ? true : false}
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyD8paCP8rBS7QNXIRgfXmaULvEqRIhcg1s"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: isMobile ? 400 : 535 }} />}
                mapElement={<div style={{ height: `100%` }} />}
                position={mapPosition}
                intl={intl}
                paths={points}
                version={version}
                info={data.pop_tot ? ({
                  pop: data.pop_tot,
                  workers: data.workers
                }) :
                  ''}
              />
            </div>
          </div>
          {isMobile ? (
            <div className="row">
              <div className="col-12">
                <span className={(fullData ? styles.opened + ' ' : '') + styles.fullDataSwitch} onClick={this.toggleFullData}>{intl.formatMessage({ id: 'demo.full' })}</span>
              </div>
            </div>
          ) : ''}
          {isMobile & fullData ? (
            version === 1 ? (
              <div className={styles.fullData}>{fullDataAreaBlock(isoline)}</div>
            ) : (
                <div className={styles.fullData}>{fullDataBlock(data)}</div>
              )
          ) : ''}
        </div>
      </>
    )
  }
}


export default compose(
  injectIntl
)(DemoCatchmentData)
