import React from "react"
import styles from "./header.module.css"
import PropTypes from "prop-types"
// import ImageFluid from '../Image/imageFluid'
import logo_dark from '../../images/logos/logo_dark.svg'
import { Link } from "gatsby"
import { navigation } from '../../variables/navigation'
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher'
import { compose } from "recompose";
import { injectIntl } from "react-intl";

const classnames = (transparent, isFirst) => {
  if (transparent) {
    if (!isFirst) {
      return styles.header + ' ' + styles.transparent + ' ' + styles.sticky
    } else {
      return styles.header + ' ' + styles.transparent
    }
  } else {
    return styles.header
  }
}

const renderList = (array, n, pathname) => {
  const ulClass = (n) => {
    switch (n) {
      case 0:
        return styles.navigation
      case 1:
        return styles.childrenNavigation + ' ' + styles.hidden
      case 2:
        return styles.childrenSubNavigation
      default:
        return styles.navigation
    }
  }

  const liClass = (n) => {
    switch (n) {
      case 0:
        return styles.navigationItem
      case 1:
        return styles.childrenNavigationItem
      case 2:
        return styles.childrenSubNavigationItem
      default:
        return styles.navigationItem
    }
  }

  const currentSlug = pathname.split('/en').pop().replace(/\//g, '');

  const isContact = (label) => label === 'Kontakt' || label === 'Contact'

  return (
    <ul className={ulClass(n)}>
      {array.map((item, i) => {
        return (
          item.label !== 'Język' && item.label !== 'Language' ? (
            <li key={i} className={liClass(n)}>
              {item.link ? (
                <>
                  {pathname && currentSlug === item.link.split('en/').pop().split('/')[0] && n === 2 ? (
                    <>
                      <a href={"/" + item.link} className={styles.link}>
                        {item.label}
                      </a>
                      {item.badge ? <a href={"/" + item.link} className={styles.badge}>demo</a> : ``}
                    </>
                  ) : (
                      <>
                        {item.icon && (
                          <div className={styles.iconForTitle}>
                            <img src={item.icon} alt="" width={30} height={30}/>
                          </div>
                        )}
                        <Link to={"/" + item.link + "/"} className={isContact(item.label) ? styles.contactButton : styles.link}>
                          {item.label}
                        </Link>
                        {item.badge ? <Link to={"/" + item.link + "/"} className={styles.badge}>demo</Link> : ``}
                      </>
                    )}
                </>
              ) : (
                  <span className={styles.link} >
                    {item.label}
                    {item.children.length > 0 && <i className={styles.arrow}></i> }
                  </span>
                )}
              {item.children ? (
                renderList(item.children, n === 1 ? 2 : 1, pathname)
              ) : ''}
            </li>
          ) : ''
        )
      })}
      {n === 0 ? <LanguageSwitcher /> : ''}
    </ul>
  )
}


class Header extends React.Component {
  render() {
    const { transparent, isFirst, pathname, intl } = this.props
    return (
      <header className={classnames(transparent, isFirst)}>
        <div className="row">
          <div className={styles.headerCol + ' col-12 center'}>
            <div className={styles.logoNav}>
              {!isFirst ? (
                <Link to={intl.formatMessage({ id: 'nav.home' })}>
                  {/* <ImageFluid filename="logo_dark.png" /> */}
                  <img src={logo_dark} alt="Locit Logo" />
                </Link>
              ) : ''}
            </div>
            {renderList(navigation(intl), 0, pathname)}
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  transparent: PropTypes.bool,
  isFirst: PropTypes.bool,
  pathname: PropTypes.string
}

Header.defaultProps = {
  transparent: false,
  isFirst: false,
  pathname: ``
}

export default compose(
  injectIntl
)(Header)
