import React from "react";
import { Link } from "gatsby";
import { languages, getLocalizedPath } from "../../i18n";
import { PageContext } from "../../pageContext";
import styles from "./languageSwitcher.module.css"

import pl from "../../images/pl.png";
import en from "../../images/en.png";

const flags = {
	pl,
	en
};


class LanguageSwitcher extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			opened: false
		};
	}

	handleToggle = () => {
		this.setState({ opened: !this.state.opened })
	}

	render() {
		const { opened } = this.state
		return (
			<PageContext.Consumer>
				{({ originalPath, locale }) => (
					<div className={styles.languageSwitcher}>
						<div className={styles.current + " " + (opened ? styles.opened : '')} onClick={this.handleToggle}>
							<img
								src={flags[locale]}
								alt={locale}
							/> {locale}</div>
						{opened ? (
							<ul className={styles.languageList}>
								{
									languages.map(lang =>
										lang.locale !== locale ? (
											<li className={styles.listItem} key={lang.locale}>
												<Link
													to={getLocalizedPath(originalPath, lang.locale)}
													className={styles.langItem}
												>
													{lang.label}
												</Link>
											</li>
										) : ''
									)
								}
							</ul>
						) : ''}
					</div>
				)}
			</PageContext.Consumer>
		)
	}
}

export default LanguageSwitcher;