import React from "react"
import styles from "./featureIcon.module.css"
import PropTypes from "prop-types"
import analytics from '../../images/icons/analytics.svg'
import bi_crm from '../../images/icons/bi_crm.svg'
import ecommerce from '../../images/icons/ecommerce.svg'
import devs from '../../images/icons/devs.svg'
import analytics_light from '../../images/icons/analytics_light.svg'
import bi_crm_light from '../../images/icons/bi_crm_light.svg'
import ecommerce_light from '../../images/icons/ecommerce_light.svg'
import devs_light from '../../images/icons/devs_light.svg'


const renderIcon = (icon, text) => {
  switch (icon) {
    case 'analytics':
      return <img src={analytics} alt={text} />
    case 'bi_crm':
      return <img src={bi_crm} alt={text} />
    case 'ecommerce':
      return <img src={ecommerce} alt={text} />
    case 'devs':
      return <img src={devs} alt={text} />
    default:
      return <img src={analytics} alt={text} />
  }
}

const renderLightIcon = (icon, text) => {
  switch (icon) {
    case 'analytics':
      return <img src={analytics_light} alt={text} />
    case 'bi_crm':
      return <img src={bi_crm_light} alt={text} />
    case 'ecommerce':
      return <img src={ecommerce_light} alt={text} />
    case 'devs':
      return <img src={devs_light} alt={text} />
    default:
      return <img src={analytics_light} alt={text} />
  }
}

const FeatureIcon = ({ icon, text, small }) => (
  <div className={small ? styles.featureIcon + ' ' + styles.smallIcon : styles.featureIcon}>
    {small ? renderLightIcon(icon, text) : renderIcon(icon, text)}
    <span className={styles.featureText}>{text}</span>
  </div>
)

FeatureIcon.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string
}

FeatureIcon.defaultProps = {
  icon: ``,
  text: ``
}

export default FeatureIcon
