/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { compose } from "recompose";
import { injectIntl } from "react-intl";
import logo from '../../images/logos/logo_dark.svg'

function SEO ({ description, lang, meta, title, intl }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaTitle = title || intl.formatMessage({ id: 'seo.title' })
  const metaDescription = description || intl.formatMessage({ id: 'seo.desc' })

  return (
    <Helmet
      htmlAttributes={{
        lang: intl.formatMessage({ id: 'seo.lang' }),
      }}
      title={metaTitle}
      titleTemplate={intl.formatMessage({ id: 'seo.title' }) !== metaTitle ? `%s | ${intl.formatMessage({ id: 'seo.title' })}` : metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: logo,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: intl.formatMessage({ id: 'seo.keywords' })
        },
        {
          name: `robots`,
          content: `index, follow`
        }
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `pl`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default compose(
  injectIntl
)(SEO)