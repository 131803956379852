import React from "react"
import styles from "./button.module.css"
import PropTypes from "prop-types"

const classnames = (type) => {
  switch (type) {
    case 'blue':
      return styles.button + ' ' + styles.blue
    case 'yellow':
      return styles.button + ' ' + styles.yellow
    case 'green':
      return styles.button + ' ' + styles.green
    case 'transparent':
      return styles.button + ' ' + styles.transparent
    case 'white':
      return styles.button + ' ' + styles.white
    default:
      return styles.button + ' ' + styles.blue
  }
}

const ActionButton = ({ action, text, type, noMargin }) => (
  <span className={classnames(type) + (noMargin ? ' ' + styles.noMargin : '')} onClick={action}>{text}</span>
)

ActionButton.propTypes = {
  action: PropTypes.func,
  text: PropTypes.string,
  type: PropTypes.string,
  noMargin: PropTypes.bool
}

ActionButton.defaultProps = {
  action: () => { },
  text: ``,
  type: 'blue',
  noMargin: false
}

export default ActionButton
