import React, { Component } from 'react'
import styles from "./demoDevCatchmentData.module.css"
import Button from '../../../Button/button';
import ActionButton from '../../../Button/actionButton';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import arta from 'react-syntax-highlighter/dist/esm/styles/hljs/arta';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
SyntaxHighlighter.registerLanguage('json', json);

const isMobile = typeof window !== `undefined` && window.innerWidth <= 768;
class DemoDevCatchmentArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'distance',
      mode: 'fastest;car',
      range: '',
      y: '',
      x: '',
      curl: 'curl -X GET "http://locit.eu/webservice/catchment-area/v2.2.0/distance/fastest%3Bcar/250/52.197073/21.015066?format=json&charset=UTF-8" -H "accept: application/json"',
      code: {
        "info": {
          "code": 200,
          "message": "OK response. Server understood request and returned correct response",
          "machine": "prod1",
          "version": "v2.2.0"
        },
        "data": {
          "isolines": [
            {
              "range": "120",
              "type": "SRID=4326;MULTIPOLYGON(((21.014185 52.1974182,21.0147858 52.1974182,21.0153008 52.1975899,21.0156441 52.1986198,21.0159874 52.1989632,21.0163307 52.1999931,21.016674 52.2003365,21.0170174 52.2013664,21.0173607 52.2013664,21.0180473 52.2006798,21.0183907 52.1996498,21.0189056 52.1994781,21.0209656 52.1994781,21.0214806 52.1993065,21.0218239 52.1989632,21.0223389 52.1987915,21.0237122 52.1987915,21.0242271 52.1986198,21.0242271 52.1982765,21.0231972 52.1979332,21.0231972 52.1975899,21.0242271 52.1972466,21.0242271 52.1969032,21.0231972 52.1965599,21.0228539 52.1955299,21.0223389 52.1953583,21.0218239 52.1955299,21.0214806 52.1958733,21.0209656 52.1960449,21.0204506 52.1958733,21.0202789 52.1953583,21.0204506 52.1948433,21.0207939 52.1945,21.0209656 52.193985,21.0207939 52.19347,21.0202789 52.1932983,21.0197639 52.19347,21.0194206 52.1945,21.0189056 52.1946716,21.018219 52.1946716,21.017704 52.1948433,21.0173607 52.1958733,21.0168457 52.1960449,21.0161591 52.1960449,21.0156441 52.1958733,21.0153008 52.1955299,21.0147858 52.1953583,21.0140991 52.1953583,21.0135841 52.1955299,21.0134125 52.1960449,21.0134125 52.1967316,21.0134983 52.1972466,21.0137558 52.1974182,21.014185 52.1974182)))"
            }
          ]
        }
      }
    };
  }

  handleChange = (e) => {
    const name = e.target.name
    switch (name) {
      case 'type':
        this.setState({ type: e.target.value })
        return false;
      case 'mode':
        this.setState({ mode: e.target.value })
        return false;
      case 'range':
        this.setState({ range: e.target.value })
        return false;
      case 'y':
        this.setState({ y: e.target.value })
        return false;
      case 'x':
        this.setState({ x: e.target.value })
        return false;
      default:
        this.setState({ type: e.target.value })
        return false;
    }

  }

  clearInputs = () => {
    this.setState({
      type: 'distance',
      mode: 'fastest;car',
      range: '',
      y: '',
      x: ''
    })
  }

  getData = () => {
    const { type, mode, range, y, x } = this.state;
    this.fetchAddress(type, mode, range, y, x)
  }

  fetchAddress = (type, mode, range, y, x) => {
    const url = 'https://api.locit.dev.beecommerce.pl/catchment_area/' + type + '/' + mode + '/' + range + '/' + y + '/' + x;
    fetch(url)
      .then(response => response.json())
      .then(response => {
        this.setState({
          curl: 'curl -X GET "http://locit.eu/webservice/catchment-area/v2.2.0/' + type + '/' + mode + '/' + range + '/' + y + '/' + x + '?format=json&charset=UTF-8" -H "accept: application/json"',
          code: response
        })
      })
  }

  render() {
    const { intl } = this.props
    const { type, mode, range, y, x, code, curl } = this.state
    return (
      <>
        <div className={styles.devDemo + ' row'}>
          <div className={'col-8 ' + styles.inputs}>
            <div className="row">
              <div className={isMobile ? "col-2 m0 " + styles.label : "col-2"}>
                <span>{intl.formatMessage({ id: 'demo3.dev.type_label' })}</span>
              </div>
              <div className={isMobile ? "col-10 m0" : "col-10 mb0"}>
                <label htmlFor="type">
                  {intl.formatMessage({ id: 'demo3.dev.type_desc' })}
                  <select name="type" id="type" value={type} onChange={this.handleChange} className={styles.select}>
                    <option value="distance">distance</option>
                    <option value="time">time</option>
                  </select>
                </label>
              </div>
            </div>
            <div className="row">
              <div className={isMobile ? "col-2 m0 " + styles.label : "col-2"}>
                <span>{intl.formatMessage({ id: 'demo3.dev.mode_label' })}</span>
              </div>
              <div className={isMobile ? "col-10 m0" : "col-10 mb0"}>
                <label htmlFor="mode">
                  {intl.formatMessage({ id: 'demo3.dev.mode_desc' })}
                  <select name="mode" id="mode" value={mode} onChange={this.handleChange} className={styles.select}>
                    <option value="fastest;car">fastest;car</option>
                    <option value="shortest;car">shortest;car</option>
                    <option value="bufor;circle">bufor;circle</option>
                    <option value="fastest;pedestrian">fastest;pedestrian</option>
                    <option value="shortest;pedestrian">shortest;pedestrian</option>
                  </select>
                </label>
              </div>
            </div>
            <div className="row">
              <div className={isMobile ? "col-2 m0 " + styles.label : "col-2"}>
                <span>{intl.formatMessage({ id: 'demo3.dev.range_label' })}</span>
              </div>
              <div className={isMobile ? "col-10 m0" : "col-10 mb0"}>
                <label htmlFor="range">
                  {intl.formatMessage({ id: 'demo3.dev.range_desc' })}
                  <input type="text" name="range" value={range} onChange={this.handleChange} autoComplete="off" />
                </label>
              </div>
            </div>
            <div className="row">
              <div className={isMobile ? "col-2 m0 " + styles.label : "col-2"}>
                <span>{intl.formatMessage({ id: 'demo3.dev.y_label' })}</span>
              </div>
              <div className={isMobile ? "col-10 m0" : "col-10 mb0"}>
                <label htmlFor="y">
                  {intl.formatMessage({ id: 'demo3.dev.y_desc' })}
                  <input type="text" name="y" value={y} onChange={this.handleChange} autoComplete="off" />
                </label>
              </div>
            </div>
            <div className="row">
              <div className={isMobile ? "col-2 m0 " + styles.label : "col-2"}>
                <span>{intl.formatMessage({ id: 'demo3.dev.x_label' })}</span>
              </div>
              <div className={isMobile ? "col-10 m0" : "col-10 mb0"}>
                <label htmlFor="x">
                  {intl.formatMessage({ id: 'demo3.dev.x_desc' })}
                  <input type="text" name="x" value={x} onChange={this.handleChange} autoComplete="off" />
                </label>
              </div>
            </div>
          </div>
          <div className={styles.buttons + ' col-5'}>
            {!isMobile ? <Button text={intl.formatMessage({ id: 'demo.dev.swagger' })} type="green" link="https://api.locit.io/docs/?urls.primaryName=catchment-area-v2.2.0" target="blank" /> : ''}
            <div className={styles.buttonsContainer}>
              <ActionButton text={intl.formatMessage({ id: 'button.send' })} type="blue" action={this.getData} />
              <ActionButton text={intl.formatMessage({ id: 'button.clear' })} type="transparent" action={this.clearInputs} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className={styles.responses}>{intl.formatMessage({ id: 'demo.dev.responses' })}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-1">
            <span>Curl</span>
          </div>
          <div className="col-11">
            <div className={styles.code}>
              <SyntaxHighlighter language="json" style={arta} customStyle={{ color: '#fff', padding: 15 }}>
                {curl}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-1">
            <span>{intl.formatMessage({ id: 'demo.dev.code' })}</span>
          </div>
          <div className="col-11">
            <div className={styles.code}>
              <SyntaxHighlighter language="json" style={arta} customStyle={{ color: '#fff', padding: 15 }}>
                {JSON.stringify(code, null, 2)}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>
        {isMobile ? <div className="row">
          <div className="col-12"> <Button text={intl.formatMessage({ id: 'demo.dev.swagger' })} type="green" link="https://api.locit.io/docs/?urls.primaryName=catchment-area-v2.2.0" target="blank" /> </div>
        </div> : ''}
      </>
    )
  }
}


export default compose(
  injectIntl
)(DemoDevCatchmentArea)
