import React from 'react'
import { FormattedMessage } from 'react-intl';

export const fullDataGeocodingBlock = (address) => {
  return (
    <div>
      <strong><FormattedMessage id="demo.full.loc_label" /></strong>
      {address.label !== undefined && <p><FormattedMessage id="demo.full.label" /><span>{address.label}</span></p>}
      {address.country !== undefined && <p><FormattedMessage id="demo.full.country" /><span>{address.country}</span></p>}
      {address.country_name !== undefined && <p><FormattedMessage id="demo.full.country_name" /><span>{address.country_name}</span></p>}
      {address.state !== undefined && <p><FormattedMessage id="demo.full.state" /><span>{address.state}</span></p>}
      {address.county !== undefined && <p><FormattedMessage id="demo.full.country" /><span>{address.county}</span></p>}
      {address.city !== undefined && <p><FormattedMessage id="demo.full.city" /><span>{address.city}</span></p>}
      {address.district !== undefined && <p><FormattedMessage id="demo.full.city_district" /><span>{address.district}</span></p>}
      {address.street !== undefined && <p><FormattedMessage id="demo.full.street" /><span>{address.street}</span></p>}
      {address.building !== undefined && <p><FormattedMessage id="demo.full.building" /><span>{address.building}</span></p>}
      {address.postal_code !== undefined && <p><FormattedMessage id="demo.full.postal_code" /><span>{address.postal_code}</span></p>}
      {address.y !== undefined && <p><FormattedMessage id="demo.full.y" /><span>{address.y}</span></p>}
      {address.x !== undefined && <p><FormattedMessage id="demo.full.x" /><span>{address.x}</span></p>}
      {address.location_id !== undefined && <p><FormattedMessage id="demo.full.location_id" /><span>{address.location_id}</span></p>}
      {address.location_type !== undefined && <p><FormattedMessage id="demo.full.location_type" /><span>{address.location_type}</span></p>}
      {address.match_level !== undefined && <p><FormattedMessage id="demo.full.match_level" /><span>{address.match_level}</span></p>}
      {address.match_type !== undefined && <p><FormattedMessage id="demo.full.match_type" /><span>{address.match_type}</span></p>}
      {address.status !== undefined && <p><FormattedMessage id="demo.full.quality" /><span>{address.status}</span></p>}
      {address.message !== undefined && <p><FormattedMessage id="demo.full.notes" /><span>{address.message}</span></p>}
    </div>
  )
}