import React from "react"
import styles from "./widget.module.css"
import PropTypes from "prop-types"
import OptionSwitch from "../OptionSwitch/optionSwitch";
import standaryzacja from '../../images/icons/standaryzacja.svg'
import dane_o_punktach from '../../images/icons/dane_o_punktach.svg'
import potencjal from '../../images/icons/potencjal.svg'
import autouzupelnianie from '../../images/icons/autouzupelnianie.svg'
import wsp_geo from '../../images/icons/wsp_geo.svg'
import obliczanie from '../../images/icons/obliczanie.svg'
import DemoAddressAutocomplete from "../demo/addressAutocomplete/demo/demoAddressAutocomplete";
import DemoDevAddressAutocomplete from "../demo/addressAutocomplete/demoDev/demoDevAddressAutocomplete";
import DemoAddressHygiene from "../demo/addressHygiene/demo/demoAddressHygiene";
import DemoDevAddressHygiene from "../demo/addressHygiene/demoDev/demoDevAddressHygiene";
import DemoCatchmentData from "../demo/catchmentData/demo/demoCatchmentData";
import DemoDevCatchmentData from "../demo/catchmentData/demoDev/demoDevCatchmentData";
import DemoDevBuildingInfo from "../demo/addressAutocomplete/demoDev/demoDevBuildingInfo";
import DemoDevCatchmentArea from "../demo/catchmentData/demoDev/demoDevCatchmentArea";
import DemoDevGeocoding from "../demo/addressHygiene/demoDev/demoDevGeocoding";
import { compose } from "recompose";
import { injectIntl } from "react-intl";

const isMobile = typeof window !== `undefined` && window.innerWidth <= 768;

class Widget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 0,
      demo: 'adresy',
      mobileDesc: false
    };
  }

  toggleView = () => {
    this.state.view === 0 ? this.setState({ view: 1 }) : this.setState({ view: 0 })
  }

  renderApiItem = (api, intl) => {
    switch (api) {
      case 'standaryzacja':
        return <><span><img src={standaryzacja} alt={intl.formatMessage({ id: 'demo.api.standaryzacja' })} /></span> {intl.formatMessage({ id: 'demo.api.standaryzacja' })}</>
      case 'autouzupelnianie':
        return <><span><img src={autouzupelnianie} alt={intl.formatMessage({ id: 'demo.api.autouzupelnianie' })} /></span>{intl.formatMessage({ id: 'demo.api.autouzupelnianie' })}</>
      case 'potencjal':
        return <><span><img src={potencjal} alt={intl.formatMessage({ id: 'demo.api.potencjal' })} /></span>{intl.formatMessage({ id: 'demo.api.potencjal' })}</>
      case 'dane':
        return <><span><img src={dane_o_punktach} alt={intl.formatMessage({ id: 'demo.api.dane_o_punktach' })} /></span>{intl.formatMessage({ id: 'demo.api.dane_o_punktach' })}</>
      case 'geokodowanie':
        return <><span><img src={wsp_geo} alt={intl.formatMessage({ id: 'demo.api.wsp_geo' })} /></span>{intl.formatMessage({ id: 'demo.api.wsp_geo' })}</>
      case 'obliczanie':
        return <><span><img src={obliczanie} alt={intl.formatMessage({ id: 'demo.api.obliczanie' })} /></span>{intl.formatMessage({ id: 'demo.api.obliczanie' })}</>
      default:
        return <><span><img src={standaryzacja} alt={intl.formatMessage({ id: 'demo.api.obliczanie' })} /></span>{intl.formatMessage({ id: 'demo.api.obliczanie' })}</>
    }
  }

  renderContent = (demo, version, intl) => {
    switch (demo) {
      case 'adresy':
        return {
          title: intl.formatMessage({ id: 'demo1.title' }),
          desc: intl.formatMessage({ id: 'demo1.desc' }),
          demo: <DemoAddressAutocomplete version={version} />,
          devDemo: version === 1 ? <DemoDevBuildingInfo /> : <DemoDevAddressAutocomplete />,
          api: ['autouzupelnianie', 'dane']
        }
      case 'standaryzacja':
        return {
          title: intl.formatMessage({ id: 'demo2.title' }),
          desc: intl.formatMessage({ id: 'demo2.desc' }),
          demo: <DemoAddressHygiene version={version} />,
          devDemo: version === 1 ? <DemoDevGeocoding /> : <DemoDevAddressHygiene />,
          api: ['standaryzacja', 'geokodowanie', 'dane']
        }
      case 'strefy':
        return {
          title: intl.formatMessage({ id: 'demo3.title' }),
          desc: intl.formatMessage({ id: 'demo3.desc' }),
          demo: <DemoCatchmentData version={version} />,
          devDemo: version === 1 ? <DemoDevCatchmentArea /> : <DemoDevCatchmentData />,
          api: ['autouzupelnianie', 'obliczanie', 'potencjal']
        }
      default:
        return {
          title: intl.formatMessage({ id: 'demo1.title' }),
          desc: intl.formatMessage({ id: 'demo1.desc' }),
          demo: <DemoAddressAutocomplete version={version} />,
          devDemo: <DemoDevAddressAutocomplete version={version} />,
          api: ['autouzupelnianie', 'dane']
        }
    }
  }

  openDesc = () => {
    this.setState({
      mobileDesc: !this.state.mobileDesc
    })
  }

  render () {
    const { view, mobileDesc } = this.state;
    const { visible, toggleWidget, type, version, intl } = this.props;
    return (
      <div className={(visible ? styles.widget + ' ' + styles.visible : styles.widget) + ' widget-fp'}>
        <div className={styles.widgetContainer}>
          {isMobile ? (
            <>
              <div className="row">
                <div className={styles.mobileWidgetTop + " col-12"}>
                  <OptionSwitch first={intl.formatMessage({ id: 'demo.marketer' })} second={intl.formatMessage({ id: 'demo.deweloper' })} toggle={this.toggleView} />
                  <div className={styles.closeWidget} onClick={toggleWidget}>x</div>
                </div>
              </div>
            </>
          ) : (
              <>
                <div className={styles.closeWidget} onClick={toggleWidget}>x</div>
                <div className="row">
                  <div className="col-8">
                    <h3 className={styles.widgetTitle}>{this.renderContent(type, version, intl).title} {view === 1 ? <small className={styles.devView}>{intl.formatMessage({ id: 'demo.dev_view' })}</small> : ''}</h3>
                  </div>
                  <div className="col-4 right">
                    <OptionSwitch first={intl.formatMessage({ id: 'demo.marketer' })} second={intl.formatMessage({ id: 'demo.deweloper' })} toggle={this.toggleView} />
                  </div>
                </div>
              </>
            )}
          {
            view === 0 ? (
              <div className={styles.demoContent}>
                {isMobile ? (
                  <div className="row">
                    <div className={styles.mobileWidgetTitle + " col-12"}>
                      <h3 className={styles.widgetTitle}>{this.renderContent(type, version, intl).title} {view === 1 ? <small className={styles.devView}>{intl.formatMessage({ id: 'demo.dev_view' })}</small> : ''}</h3>
                      {view === 0 ? <span className={mobileDesc ? styles.descSwitch + ' ' + styles.opened : styles.descSwitch} onClick={this.openDesc}>{intl.formatMessage({ id: 'demo.desc_view' })}</span> : ''}
                    </div>
                  </div>
                ) : ''}
                {!isMobile || (isMobile && mobileDesc) ? (
                  <div className="row">
                    <div className="col-12">
                      <p className={styles.desc}>{this.renderContent(type, version, intl).desc}</p>
                    </div>
                  </div>
                ) : ''}
                {this.renderContent(type, version, intl).demo}
                <div className="row">
                  <div className="col-12">
                    <h5 className={styles.widgetSubheader}>{intl.formatMessage({ id: 'demo.api' })}</h5>
                    <ul className={styles.apiList}>
                      {this.renderContent(type, version, intl).api.map((item, i) => {
                        return <li key={i} className={styles.apiListItem}>{this.renderApiItem(item, intl)}</li>
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
                <div className={styles.demoContent}>{this.renderContent(type, version, intl).devDemo}</div>
              )
          }
        </div>
      </div >
    )
  }

}

Widget.propTypes = {
  visible: PropTypes.bool,
  toggleWidget: PropTypes.func,
  type: PropTypes.string,
  version: PropTypes.string
}

Widget.defaultProps = {
  visible: false,
  toggleWidget: () => { },
  type: ``,
  version: ``
}

export default compose(
  injectIntl
)(Widget)