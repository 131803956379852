import React from "react"
import Layout from "../Layout/layout"
import SEO from "../Seo/seo"
import PageBanner from "../PageBanner/pageBanner"
import ReactFullpage from '@fullpage/react-fullpage';
import FeatureSection from "../FeatureSection/featureSection";
import Footer from "../Footer/footer";
import Widget from "../Widget/widget";
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { TypographyStyle, GoogleFont } from 'react-typography'
import typography from '../../utils/typography'
import FullPageNav from "../FullPageNav/fullPageNav";
import { injectIntl, intlShape } from "react-intl";
import { compose } from "recompose";

class productPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      widget: false,
      isFirst: true,
      isLast: false,
      index: null
    };
  }

  componentWillUnmount() {
    document.body.children.fullPageNav.remove()
  }

  getSlidePosition(slide) {
    this.setState({
      index: slide.index,
      isFirst: slide.isFirst,
      isLast: slide.isLast
    })
  }

  toggleWidget = (type, version) => {
    if (this.state.widget === true) {
      this.setState({ widget: false, widgetType: null })
      typeof window !== `undefined` && window.fullpage_api.setAllowScrolling(true)
    } else {
      this.setState({ widget: true, widgetType: type, widgetVersion: version })
      typeof window !== `undefined` && window.fullpage_api.setAllowScrolling(false)
    }
  }

  checkHash = (destination, pathname) => {
    if (destination.anchor === "") {
      navigate(pathname)
    }
  }

  render() {
    const { data, uri, pathname, intl } = this.props;
    const { widget, isFirst, isLast, index, widgetType, widgetVersion } = this.state;
    return (
      <Layout location={uri} pathname={pathname}>
        <SEO title={data.title} description={data.desc} />
        <TypographyStyle typography={typography} />
        <GoogleFont typography={typography} />
        <Widget visible={widget} toggleWidget={this.toggleWidget} type={widgetType} version={widgetVersion} />
        <ReactFullpage
          licenseKey="7FB503B7-AA154F20-A5BE7619-2C017CE4"
          navigation="true"
          fixedElements={['#fullPageNav']}
          anchors={data.anchors}
          recordHistory={false}
          onLeave={(origin, destination) => {
            this.checkHash(destination, pathname);
            this.getSlidePosition(destination)
          }}
          normalScrollElements='.widget-fp'
          render={({ state, fullpageApi }) => {
            return (
              <>
                <FullPageNav up={() => fullpageApi.moveSectionUp()} down={() => fullpageApi.moveSectionDown()} type='dark' index={index} isFirst={isFirst} isLast={isLast} />
                <ReactFullpage.Wrapper>
                  <PageBanner title={data.title} desc={data.desc} features={data.features} />
                  {data.sections.map((section, i) => {
                    return <FeatureSection
                      key={i}
                      title={section.title}
                      text={section.desc}
                      img={section.img}
                      link={section.link}
                      imageRight={section.imageRight}
                      background={section.background}
                      type={section.widget ? "yellow" : "green"}
                      buttonText={section.widget ? intl.formatMessage({ id: 'button.test' }) : intl.formatMessage({ id: 'button.docs' })}
                      action={section.widget ? () => this.toggleWidget(section.widget, section.widgetVersion) : null}
                      target={section.target}
                    />
                  })}
                  <Footer />
                </ReactFullpage.Wrapper>
              </>
            );
          }}
        />
      </Layout>
    )
  }
}


productPage.propTypes = {
  data: PropTypes.any,
  uri: PropTypes.string,
  pathname: PropTypes.string,
  intl: intlShape.isRequired
}

productPage.defaultProps = {
  data: {},
  uri: ``,
  pathname: ``
}

export default compose(
  injectIntl
)(productPage)