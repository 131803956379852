import React from "react"
import styles from "./backgroundSection.module.css"
import PropTypes from "prop-types"
import BackgroundImage from "gatsby-background-image"
import { graphql, StaticQuery } from "gatsby"

const Section = ({
  children,
  color,
  textColor,
  filename,
  size,
  position,
  isFullPage,
}) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(quality: 90, maxWidth: 4160) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(filename)
      })
      if (!image) {
        return null
      }

      const imageSizes = image.node.childImageSharp.fluid
      return (
        <BackgroundImage
          className={`section ${styles.backgroundSection} ${isFullPage &&
            styles.backgroundSectionFull}`}
          fluid={imageSizes}
          backgroundColor={color}
          style={{
            backgroundSize: size,
            backgroundPosition: position,
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className={styles.container} style={{ color: textColor }}>
            {children}
          </div>
        </BackgroundImage>
      )
    }}
  />
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  textColor: PropTypes.string,
  filename: PropTypes.string,
  size: PropTypes.string,
  position: PropTypes.string,
  isFullPage: PropTypes.bool,
  isContentHeight: PropTypes.bool,
}

Section.defaultProps = {
  color: "#fff",
  textColor: "#000",
  filename: "",
  position: "center",
  size: "cover",
  isFullPage: false,
  isContentHeight: false,
}

export default Section
