import mapy from '../images/icons/nav/mapy.svg'
import adresy from '../images/icons/nav/adresy-dane.svg'
import geokodowanie from '../images/icons/nav/geokodowanie.svg'
import statystyczne from '../images/icons/nav/dane-statystyczne.svg'
import obliczenia from '../images/icons/nav/obliczenia.svg'

export const navigation = (intl) => [
  {
    label: intl.formatMessage({ id: 'nav.produkty' }),
    link: null,
    children: [
      {
        label: intl.formatMessage({ id: 'nav.produkty.adresy' }),
        link: intl.formatMessage({ id: 'nav.produkty.adresy.slug' }),
        icon: adresy,
        children: [
          {
            label: intl.formatMessage({ id: 'nav.produkty.adresy.autouzupelnianie' }),
            link: intl.formatMessage({ id: 'nav.produkty.adresy.slug' }) + "/#" + intl.formatMessage({ id: 'nav.produkty.adresy.autouzupelnianie.slug' }),
            badge: true,
          },
          {
            label: intl.formatMessage({ id: 'nav.produkty.adresy.standaryzacja' }),
            link: intl.formatMessage({ id: 'nav.produkty.adresy.slug' }) + "/#" + intl.formatMessage({ id: 'nav.produkty.adresy.standaryzacja.slug' }),
            badge: true,
          },
          {
            label: intl.formatMessage({ id: 'nav.produkty.adresy.formatowanie' }),
            link: intl.formatMessage({ id: 'nav.produkty.adresy.slug' }) + "/#" + intl.formatMessage({ id: 'nav.produkty.adresy.formatowanie.slug' }),
            badge: false,
          },
          {
            label: intl.formatMessage({ id: 'nav.produkty.adresy.walidacja' }),
            link: intl.formatMessage({ id: 'nav.produkty.adresy.slug' }) + "/#" + intl.formatMessage({ id: 'nav.produkty.adresy.walidacja.slug' }),
            badge: false,
          },
        ],
      },
      {
        label: intl.formatMessage({ id: 'nav.produkty.mapy' }),
        link: intl.formatMessage({ id: 'nav.produkty.mapy.slug' }),
        icon: mapy,
        children: [
          {
            label: intl.formatMessage({ id: 'nav.produkty.mapy.poi' }),
            link: intl.formatMessage({ id: 'nav.produkty.mapy.slug' }) + "/#" + intl.formatMessage({ id: 'nav.produkty.mapy.poi.slug' }),
            badge: false,
          },
          {
            label: intl.formatMessage({ id: 'nav.produkty.mapy.punkty' }),
            link: intl.formatMessage({ id: 'nav.produkty.mapy.slug' }) + "/#" + intl.formatMessage({ id: 'nav.produkty.mapy.punkty.slug' }),
            badge: false,
          },
        ],
      },
      {
        label: intl.formatMessage({ id: 'nav.produkty.geokodowanie' }),
        link: intl.formatMessage({ id: 'nav.produkty.geokodowanie.slug' }),
        icon: geokodowanie,
        children: [
          {
            label: intl.formatMessage({ id: 'nav.produkty.geokodowanie.adresy' }),
            link: intl.formatMessage({ id: 'nav.produkty.geokodowanie.slug' }) + "/#" + intl.formatMessage({ id: 'nav.produkty.geokodowanie.adresy.slug' }),
            badge: true,
          },
          {
            label: intl.formatMessage({ id: 'nav.produkty.geokodowanie.odwrotne' }),
            link: intl.formatMessage({ id: 'nav.produkty.geokodowanie.slug' }) + "/#" + intl.formatMessage({ id: 'nav.produkty.geokodowanie.odwrotne.slug' }),
            badge: false,
          },
        ],
      },
      {
        label: intl.formatMessage({ id: 'nav.produkty.dane' }),
        link: intl.formatMessage({ id: 'nav.produkty.dane.slug' }),
        icon: statystyczne,
        children: [
          {
            label: intl.formatMessage({ id: 'nav.produkty.dane.potencjal' }),
            link: intl.formatMessage({ id: 'nav.produkty.dane.slug' }) + "/#" + intl.formatMessage({ id: 'nav.produkty.dane.potencjal.slug' }),
            badge: true,
          },
          {
            label: intl.formatMessage({ id: 'nav.produkty.dane.punkty' }),
            link: intl.formatMessage({ id: 'nav.produkty.dane.slug' }) + "/#" + intl.formatMessage({ id: 'nav.produkty.dane.punkty.slug' }),
            badge: true,
          },
        ],
      },
      {
        label: intl.formatMessage({ id: 'nav.produkty.obliczenia' }),
        link: intl.formatMessage({ id: 'nav.produkty.obliczenia.slug' }),
        icon: obliczenia,
        children: [
          {
            label: intl.formatMessage({ id: 'nav.produkty.obliczenia.strefy' }),
            link: intl.formatMessage({ id: 'nav.produkty.obliczenia.slug' }) + "/#" + intl.formatMessage({ id: 'nav.produkty.obliczenia.strefy.slug' }),
            badge: true,
          },
        ],
      },
    ],
  },
  {
    label: intl.formatMessage({ id: 'nav.saas-on-premise' }),
    link: intl.formatMessage({ id: 'nav.saas-on-premise.slug' }),
  },
  {
    label: intl.formatMessage({ id: 'nav.dlaczego-my' }),
    link: intl.formatMessage({ id: 'nav.dlaczego-my.slug' }),
  },
  {
    label: intl.formatMessage({ id: 'nav.cennik' }),
    link: intl.formatMessage({ id: 'nav.cennik.slug' }),
  },
  {
    label: intl.formatMessage({ id: 'nav.kontakt' }),
    link: intl.formatMessage({ id: 'nav.kontakt.slug' }),
  },
  {
    label: intl.formatMessage({ id: 'nav.language' }),
    link: null,
    children: [
      {
        label: "Polski",
        link: "pl",
      },
      {
        label: "English",
        link: "en",
      },
    ],
  },
]
