import React from "react"
import styles from "./fullPageNav.module.css"
import PropTypes from "prop-types"
import { injectIntl, intlShape } from "react-intl";
import { compose } from "recompose";


const FullPageNav = ({ type, up, down, isFirst, isLast, intl }) => (
  <div className={styles.fullPageNav + " " + (type === 'dark' ? styles.dark : styles.light)} id="fullPageNav">
    <span onClick={up} className={isFirst ? styles.deactivated : ''}>{intl.formatMessage({ id: 'nav.prev' })}</span>
    <span onClick={down} className={isLast ? styles.deactivated : ''}>{intl.formatMessage({ id: 'nav.next' })}</span>
  </div>
)

FullPageNav.propTypes = {
  type: PropTypes.string,
  up: PropTypes.func,
  down: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  intl: intlShape.isRequired
}

FullPageNav.defaultProps = {
  type: 'dark',
  up: () => { },
  down: () => { },
  isFirst: true,
  isLast: false
}

export default compose(
  injectIntl
)(FullPageNav)