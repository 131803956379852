import React from 'react'
import { FormattedMessage } from 'react-intl';

export const fullDataBuildingBlock = (building_info) => {
  return (
    <div>
      <strong><FormattedMessage id="demo.full.building_title" /></strong>
      {building_info.uuid !== undefined && <p><FormattedMessage id="demo.full.uuid" /><span>{building_info.uuid}</span></p>}
      {building_info.zip !== undefined && <p><FormattedMessage id="demo.full.zip" /><span>{building_info.zip}</span></p>}
      {building_info.city !== undefined && <p><FormattedMessage id="demo.full.city" /><span>{building_info.city}</span></p>}
      {building_info.district !== undefined && <p><FormattedMessage id="demo.full.city_district" /><span>{building_info.district}</span></p>}
      {building_info.prefix !== undefined && <p><FormattedMessage id="demo.full.prefix" /><span>{building_info.prefix}</span></p>}
      {building_info.street !== undefined && <p><FormattedMessage id="demo.full.street" /><span>{building_info.street}</span></p>}
      {building_info.gus_cech !== undefined && <p><FormattedMessage id="demo.full.gus_cech" /><span>{building_info.gus_cech}</span></p>}
      {building_info.gus_naz1 !== undefined && <p><FormattedMessage id="demo.full.gus_naz1" /><span>{building_info.gus_naz1}</span></p>}
      {building_info.gus_naz2 !== undefined && <p><FormattedMessage id="demo.full.gus_naz2" /><span>{building_info.gus_naz2}</span></p>}
      {building_info.building !== undefined && <p><FormattedMessage id="demo.full.building" /><span>{building_info.building}</span></p>}
      {building_info.y !== undefined && <p><FormattedMessage id="demo.full.y" /><span>{building_info.y}</span></p>}
      {building_info.x !== undefined && <p><FormattedMessage id="demo.full.x" /><span>{building_info.x}</span></p>}
      {building_info.xy_class !== undefined && <p><FormattedMessage id="demo.full.xy_class" /><span>{building_info.xy_class}</span></p>}
      {building_info.ctype !== undefined && <p><FormattedMessage id="demo.full.ctype" /><span>{building_info.ctype}</span></p>}
      {building_info.csize !== undefined && <p><FormattedMessage id="demo.full.csize" /><span>{building_info.csize}</span></p>}
      {building_info.msegm !== undefined && <p><FormattedMessage id="demo.full.msegm" /><span>{building_info.msegm}</span></p>}
      {building_info.agglom !== undefined && <p><FormattedMessage id="demo.full.agglom" /><span>{building_info.agglom}</span></p>}
      {building_info.elev !== undefined && <p><FormattedMessage id="demo.full.elev" /><span>{building_info.elev}</span></p>}
      {building_info.sym !== undefined && <p><FormattedMessage id="demo.full.sym" /><span>{building_info.sym}</span></p>}
      {building_info.symd !== undefined && <p><FormattedMessage id="demo.full.symd" /><span>{building_info.symd}</span></p>}
      {building_info.symul !== undefined && <p><FormattedMessage id="demo.full.symul" /><span>{building_info.symul}</span></p>}
      {building_info.voiv !== undefined && <p><FormattedMessage id="demo.full.voiv" /><span>{building_info.voiv}</span></p>}
      {building_info.pov !== undefined && <p><FormattedMessage id="demo.full.pov" /><span>{building_info.pov}</span></p>}
      {building_info.mun !== undefined && <p><FormattedMessage id="demo.full.mun" /><span>{building_info.mun}</span></p>}
      {building_info.mun_type !== undefined && <p><FormattedMessage id="demo.full.mun_type" /><span>{building_info.mun_type}</span></p>}
      {building_info.mwg7 !== undefined && <p><FormattedMessage id="demo.full.mwg7" /><span>{building_info.mwg7}</span></p>}
      {building_info.mwg7_version !== undefined && <p><FormattedMessage id="demo.full.mwg7_version" /><span>{building_info.mwg7_version}</span></p>}
      {building_info.mwg14 !== undefined && <p><FormattedMessage id="demo.full.mwg14" /><span>{building_info.mwg14}</span></p>}
      {building_info.mwg14_version !== undefined && <p><FormattedMessage id="demo.full.mwg14_version" /><span>{building_info.mwg14_version}</span></p>}
      {building_info.egr_0125 !== undefined && <p><FormattedMessage id="demo.full.egr_0125" /><span>{building_info.egr_0125}</span></p>}
      {building_info.hex_0125 !== undefined && <p><FormattedMessage id="demo.full.hex_0125" /><span>{building_info.hex_0125}</span></p>}
      {building_info.bneigh !== undefined && <p><FormattedMessage id="demo.full.bneigh" /><span>{building_info.bneigh}</span></p>}
      {building_info.btype !== undefined && <p><FormattedMessage id="demo.full.btype" /><span>{building_info.btype}</span></p>}
      {building_info.poc_act !== undefined && <p><FormattedMessage id="demo.full.poc_act" /><span>{building_info.poc_act}</span></p>}
      {building_info.crc_tot !== undefined && <p><FormattedMessage id="demo.full.crc_tot" /><span>{building_info.crc_tot}</span></p>}
      {building_info.apts_t !== undefined && <p><FormattedMessage id="demo.full.apts_t" /><span>{building_info.apts_t}</span></p>}
      {building_info.pop_apts !== undefined && <p><FormattedMessage id="demo.full.pop_apts" /><span>{building_info.pop_apts}</span></p>}
      {building_info.pop_tot !== undefined && <p><FormattedMessage id="demo.full.pop_tot" /><span>{building_info.pop_tot}</span></p>}
      {building_info.popf_tot !== undefined && <p><FormattedMessage id="demo.full.popf_tot" /><span>{building_info.popf_tot}</span></p>}
      {building_info.popm_tot !== undefined && <p><FormattedMessage id="demo.full.popm_tot" /><span>{building_info.popm_tot}</span></p>}
      {building_info.popt0004 !== undefined && <p><FormattedMessage id="demo.full.popt0004" /><span>{building_info.popt0004}</span></p>}
      {building_info.popt0509 !== undefined && <p><FormattedMessage id="demo.full.popt0509" /><span>{building_info.popt0509}</span></p>}
      {building_info.popt1014 !== undefined && <p><FormattedMessage id="demo.full.popt1014" /><span>{building_info.popt1014}</span></p>}
      {building_info.popt1519 !== undefined && <p><FormattedMessage id="demo.full.popt1519" /><span>{building_info.popt1519}</span></p>}
      {building_info.popt2024 !== undefined && <p><FormattedMessage id="demo.full.popt2024" /><span>{building_info.popt2024}</span></p>}
      {building_info.popt2529 !== undefined && <p><FormattedMessage id="demo.full.popt2529" /><span>{building_info.popt2529}</span></p>}
      {building_info.popt3034 !== undefined && <p><FormattedMessage id="demo.full.popt3034" /><span>{building_info.popt3034}</span></p>}
      {building_info.popt3539 !== undefined && <p><FormattedMessage id="demo.full.popt3539" /><span>{building_info.popt3539}</span></p>}
      {building_info.popt4044 !== undefined && <p><FormattedMessage id="demo.full.popt4044" /><span>{building_info.popt4044}</span></p>}
      {building_info.popt4549 !== undefined && <p><FormattedMessage id="demo.full.popt4549" /><span>{building_info.popt4549}</span></p>}
      {building_info.popt5054 !== undefined && <p><FormattedMessage id="demo.full.popt5054" /><span>{building_info.popt5054}</span></p>}
      {building_info.popt5559 !== undefined && <p><FormattedMessage id="demo.full.popt5559" /><span>{building_info.popt5559}</span></p>}
      {building_info.popt6064 !== undefined && <p><FormattedMessage id="demo.full.popt6064" /><span>{building_info.popt6064}</span></p>}
      {building_info.popt6569 !== undefined && <p><FormattedMessage id="demo.full.popt6569" /><span>{building_info.popt6569}</span></p>}
      {building_info.popt7074 !== undefined && <p><FormattedMessage id="demo.full.popt7074" /><span>{building_info.popt7074}</span></p>}
      {building_info.popt7599 !== undefined && <p><FormattedMessage id="demo.full.popt7599" /><span>{building_info.popt7599}</span></p>}
      {building_info.pop_ax_t !== undefined && <p><FormattedMessage id="demo.full.pop_ax_t" /><span>{building_info.pop_ax_t}</span></p>}
      {building_info.pop_ax_y !== undefined && <p><FormattedMessage id="demo.full.pop_ax_y" /><span>{building_info.pop_ax_y}</span></p>}
      {building_info.inbld_t !== undefined && <p><FormattedMessage id="demo.full.inbld_t" /><span>{building_info.inbld_t}</span></p>}
      {building_info.inbld_w !== undefined && <p><FormattedMessage id="demo.full.inbld_w" /><span>{building_info.inbld_w}</span></p>}
      {building_info.poi_szpital !== undefined && <p><FormattedMessage id="demo.full.poi_szpital" /><span>{building_info.poi_szpital}</span></p>}
      {building_info.poi_poczta !== undefined && <p><FormattedMessage id="demo.full.poi_poczta" /><span>{building_info.poi_poczta}</span></p>}
      {building_info.poi_przedszkole !== undefined && <p><FormattedMessage id="demo.full.poi_przedszkole" /><span>{building_info.poi_przedszkole}</span></p>}
      {building_info.poi_szk_podst !== undefined && <p><FormattedMessage id="demo.full.poi_szk_podst" /><span>{building_info.poi_szk_podst}</span></p>}
      {building_info.poi_szk_wyzsza !== undefined && <p><FormattedMessage id="demo.full.poi_szk_wyzsza" /><span>{building_info.poi_szk_wyzsza}</span></p>}
      {building_info.poi_bankomat !== undefined && <p><FormattedMessage id="demo.full.poi_bankomat" /><span>{building_info.poi_bankomat}</span></p>}
      {building_info.poi_stacja_paliw !== undefined && <p><FormattedMessage id="demo.full.poi_stacja_paliw" /><span>{building_info.poi_stacja_paliw}</span></p>}
      {building_info.poi_centrala_banku !== undefined && <p><FormattedMessage id="demo.full.poi_centrala_banku" /><span>{building_info.poi_centrala_banku}</span></p>}
      {building_info.poi_oddzial_banku !== undefined && <p><FormattedMessage id="demo.full.poi_oddzial_banku" /><span>{building_info.poi_oddzial_banku}</span></p>}
      {building_info.poi_hipermarket !== undefined && <p><FormattedMessage id="demo.full.poi_hipermarket" /><span>{building_info.poi_hipermarket}</span></p>}
      {building_info.poi_supermarket !== undefined && <p><FormattedMessage id="demo.full.poi_supermarket" /><span>{building_info.poi_supermarket}</span></p>}
      {building_info.poi_dyskont !== undefined && <p><FormattedMessage id="demo.full.poi_dyskont" /><span>{building_info.poi_dyskont}</span></p>}
      {building_info.poi_convenience !== undefined && <p><FormattedMessage id="demo.full.poi_convenience" /><span>{building_info.poi_convenience}</span></p>}
      {building_info.poi_drogeria !== undefined && <p><FormattedMessage id="demo.full.poi_drogeria" /><span>{building_info.poi_drogeria}</span></p>}
      {building_info.poi_multimedia !== undefined && <p><FormattedMessage id="demo.full.poi_multimedia" /><span>{building_info.poi_multimedia}</span></p>}
      {building_info.poi_wyposazenie !== undefined && <p><FormattedMessage id="demo.full.poi_wyposazenie" /><span>{building_info.poi_wyposazenie}</span></p>}
      {building_info.poi_ch !== undefined && <p><FormattedMessage id="demo.full.poi_ch" /><span>{building_info.poi_ch}</span></p>}
      {building_info.poi_urzad !== undefined && <p><FormattedMessage id="demo.full.poi_urzad" /><span>{building_info.poi_urzad}</span></p>}
      {building_info.poi_ambasada !== undefined && <p><FormattedMessage id="demo.full.poi_ambasada" /><span>{building_info.poi_ambasada}</span></p>}
      {building_info.poi_fast_food !== undefined && <p><FormattedMessage id="demo.full.poi_fast_food" /><span>{building_info.poi_fast_food}</span></p>}
      {building_info.poi_muzeum_kino !== undefined && <p><FormattedMessage id="demo.full.poi_muzeum_kino" /><span>{building_info.poi_muzeum_kino}</span></p>}
      {building_info.poi_apteka !== undefined && <p><FormattedMessage id="demo.full.poi_apteka" /><span>{building_info.poi_apteka}</span></p>}
      {building_info.poi_pkp !== undefined && <p><FormattedMessage id="demo.full.poi_pkp" /><span>{building_info.poi_pkp}</span></p>}
      {building_info.uke_version !== undefined && <p><FormattedMessage id="demo.full.uke_version" /><span>{building_info.uke_version}</span></p>}
      {building_info.cpr !== undefined && <p><FormattedMessage id="demo.full.cpr" /><span>{building_info.cpr}</span></p>}
      {building_info.cpr_twi !== undefined && <p><FormattedMessage id="demo.full.cpr_twi" /><span>{building_info.cpr_twi}</span></p>}
      {building_info.cpr_coa !== undefined && <p><FormattedMessage id="demo.full.cpr_coa" /><span>{building_info.cpr_coa}</span></p>}
      {building_info.cpr_fib !== undefined && <p><FormattedMessage id="demo.full.cpr_fib" /><span>{building_info.cpr_fib}</span></p>}
      {building_info.mbps !== undefined && <p><FormattedMessage id="demo.full.mbps" /><span>{building_info.mbps}</span></p>}
      {building_info.mbps_twi !== undefined && <p><FormattedMessage id="demo.full.mbps_twi" /><span>{building_info.mbps_twi}</span></p>}
      {building_info.mbps_coa !== undefined && <p><FormattedMessage id="demo.full.mbps_coa" /><span>{building_info.mbps_coa}</span></p>}
      {building_info.mbps_fib !== undefined && <p><FormattedMessage id="demo.full.mbps_fib" /><span>{building_info.mbps_fib}</span></p>}
      {building_info.sfp_class !== undefined && <p><FormattedMessage id="demo.full.sfp_class" /><span>{building_info.sfp_class}</span></p>}
      {building_info.bheight !== undefined && <p><FormattedMessage id="demo.full.bheight" /><span>{building_info.bheight}</span></p>}
    </div>
  )
}