import React from 'react'
import styles from "./demoAddressHygiene.module.css"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import ActionButton from '../../../Button/actionButton';
import { Link } from 'gatsby';
import { fullDataBlock } from './fullData'
import { fullDataGeocodingBlock } from './fullDataGeocoding'
import marker from '../../../../images/icons/marker_icon.svg'
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';

const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
const isMobile = typeof window !== `undefined` && window.innerWidth <= 768;

const MapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={props.building_info ? 17 : 6}
    defaultCenter={props.position}
    center={props.position}
    zoom={props.building_info ? 17 : 6}
    defaultOptions={{ mapTypeControl: false, streetViewControl: false, zoomControl: false }}
  >
    {typeof window !== `undefined` && props.building_info && <InfoBox
      defaultPosition={new window.google.maps.LatLng(props.position.lat, props.position.lng)}
      options={{ closeBoxURL: ``, enableEventPropagation: true, maxWidth: isMobile ? 180 : 200 }}
      position={new window.google.maps.LatLng(props.position.lat, props.position.lng)}
    >
      <div style={{ backgroundColor: `white`, padding: 15, borderLeft: '2px solid #304FFE', minWidth: isMobile ? 180 : 250, maxWidth: isMobile ? 180 : 250 }}>
        <div style={{ fontSize: isMobile ? 12 : 14, fontFamily: 'Montserrat' }}>
          <span style={{ color: '#304FFE', display: 'block', marginBottom: 5 }}>{props.building_info.add1}</span>
          <span style={{ color: '#304FFE', display: 'block', marginBottom: 5 }}>{props.building_info.add2}</span>
          <span style={{ display: 'inline-block', maxWidth: 240 }}>
            <Link to='/dane-statystyczne/#punkty-adresowe' style={{ color: 'inherit' }}>{props.intl.formatMessage({ id: 'demo1.map.see_more' })}</Link>
          </span>
        </div>
      </div>
    </InfoBox>}
    {props.isMarkerShown && <Marker position={props.position} options={{ icon: marker }} />}
  </GoogleMap>
))

class DemoAddressHygiene extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullData: false,
      input1: '',
      input2: '',
      address: {},
      geocoding: {},
      position: {
        lat: 51.9984334,
        lng: 18.9926065
      }
    };
  }

  fetchAddress = (post, street) => {
    const url = 'https://api.locit.dev.beecommerce.pl/address_hygiene';
    fetch(url, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        post: post,
        street: street,
        geocoding: 1
      })
    })
      .then(response => response.json())
      .then(response => {
        const data = response.data
        if ((data.x === 0 && data.y === 0) || data.sym === "0000000") {
          this.setState({
            address: {
              quality: data.quality,
              sym: data.sym
            }
          })
        } else {
          this.setData(data)
          const url = 'https://api.locit.dev.beecommerce.pl/geocoding';
          fetch(url, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              country: data.country,
              zip: data.zip,
              city: data.city,
              street: data.street,
              building: data.building
            })
          })
            .then(response => response.json())
            .then(response => {
              const data_geo = response.data
              this.setState({
                geocoding: data_geo,
              })
            })
        }

      })
  }

  toggleFullData = () => {
    const currentFullData = this.state.fullData
    this.setState({ fullData: !currentFullData })
  }

  handleChange = (e) => {
    e.target.name === 'address1' ? this.setState({ input1: e.target.value }) : this.setState({ input2: e.target.value })
  }

  setData = (item) => {
    this.setState({
      address: item,
      position: {
        lat: parseFloat(item.y),
        lng: parseFloat(item.x)
      },
    })
  }

  clearData = () => {
    this.setState({
      fullData: false,
      input1: '',
      input2: '',
      address: {},
      geocoding: {},
      position: {
        lat: 51.9984334,
        lng: 18.9926065
      }
    })
  }

  render () {
    const { fullData, position, address, input1, input2, geocoding } = this.state
    const { version, intl } = this.props
    return (
      <>
        {!isMobile ? (
          <div className="row">
            <div className="col-6">
              <h5 className={styles.demoSubtitle}>{intl.formatMessage({ id: 'demo2.test' })}</h5>
            </div>
            <div className="col-6 right">
              <span className={(fullData ? styles.opened + ' ' : '') + styles.fullDataSwitch} onClick={this.toggleFullData}>{intl.formatMessage({ id: 'demo.full' })}</span>
            </div>
          </div>
        ) : ''}
        <div className={styles.demo + ' row'}>
          <div className="col-5">
            <label htmlFor="address1">
              {intl.formatMessage({ id: 'demo2.zip_city' })}
              <input
                type="text"
                name="address1"
                onChange={this.handleChange}
                placeholder={intl.formatMessage({ id: 'demo2.zip_city_placeholder' })}
                value={input1}
                autoComplete="off"
              />
            </label>
            <label htmlFor="address2">
              {intl.formatMessage({ id: 'demo2.address' })}
              <input
                type="text"
                name="address2"
                onChange={this.handleChange}
                placeholder={intl.formatMessage({ id: 'demo2.address_placeholder' })}
                value={input2}
                autoComplete="off"
              />
            </label>
            <span className={styles.gap}></span>
            <div className={styles.buttons}>
              <ActionButton text={intl.formatMessage({ id: 'button.clear' })} type="transparent" noMargin action={this.clearData} />
              <ActionButton text={intl.formatMessage({ id: 'button.send' })} type="blue" noMargin action={() => this.fetchAddress(input1, input2)} />
            </div>
            {address.quality ? (
              <>
                <h5 className={styles.demoSubtitle}>Status</h5>
                <p className={address.sym === "0000000" ? styles.error : ''}>{address.quality}</p>
              </>
            ) : ''}
          </div>
          <div className={styles.dataContainer + " col-7"}>
            {!isMobile & fullData ? (
              version === 1 ? (
                <div className={styles.fullData}>{fullDataGeocodingBlock(geocoding)}</div>
              ) : (
                  <div className={styles.fullData}>{fullDataBlock(address)}</div>
                )
            ) : ''}
            <div className={styles.map}>
              <MapComponent
                isMarkerShown={address.street ? true : false}
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyD8paCP8rBS7QNXIRgfXmaULvEqRIhcg1s"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: isMobile ? 300 : 430 }} />}
                mapElement={<div style={{ height: `100%` }} />}
                position={position}
                intl={intl}
                building_info={address.street ? (
                  {
                    add1: address.prefix + ' ' + address.street + ' ' + address.building,
                    add2: address.zip + ' ' + address.city
                  }
                ) :
                  ''}
              />
            </div>
          </div>
          {isMobile ? (
            <div className="row">
              <div className="col-12">
                <span className={(fullData ? styles.opened + ' ' : '') + styles.fullDataSwitch} onClick={this.toggleFullData}>{intl.formatMessage({ id: 'demo.full' })}</span>
              </div>
            </div>
          ) : ''}
          {isMobile & fullData ? (
            version === 1 ? (
              <div className={styles.fullData}>{fullDataGeocodingBlock(geocoding)}</div>
            ) : (
                <div className={styles.fullData}>{fullDataBlock(address)}</div>
              )
          ) : ''}
        </div>
      </>
    )
  }
}


export default compose(
  injectIntl
)(DemoAddressHygiene)