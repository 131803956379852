import React from "react"
import ProductPage from "../components/ProductPage/productPage";
import autouzupelnianie from '../images/gifs/pp_autouzupelnianie.gif'
import ulice from '../images/gifs/pp_ulice.gif'
import nip from '../images/gifs/pp_nip.gif'
import telefon from '../images/gifs/pp_numer_telefonu.gif'
import { injectIntl, intlShape } from "react-intl";
import { compose } from "recompose";
import withPageContext from "../pageContext";

const data = (intl) => {
  return {
    title: intl.formatMessage({ id: 'adresy.title' }),
    desc: intl.formatMessage({ id: 'adresy.desc' }),
    anchors: ['', intl.formatMessage({ id: 'nav.produkty.adresy.autouzupelnianie.slug' }), intl.formatMessage({ id: 'nav.produkty.adresy.standaryzacja.slug' }), intl.formatMessage({ id: 'nav.produkty.adresy.formatowanie.slug' }), intl.formatMessage({ id: 'nav.produkty.adresy.walidacja.slug' }), ''],
    sections: [
      {
        title: intl.formatMessage({ id: 'adresy.autouzupelnianie.title' }),
        desc: intl.formatMessage({ id: 'adresy.autouzupelnianie.desc' }),
        img: autouzupelnianie,
        link: null,
        widget: "adresy",
        widgetVersion: 0,
        imageRight: true,
        background: "01_blue.png"
      },
      {
        title: intl.formatMessage({ id: 'adresy.standaryzacja.title' }),
        desc: intl.formatMessage({ id: 'adresy.standaryzacja.desc' }),
        img: ulice,
        link: null,
        widget: "standaryzacja",
        widgetVersion: 0,
        imageRight: false,
        background: "02_blue.png"
      },
      {
        title: intl.formatMessage({ id: 'adresy.walidacja.title' }),
        desc: intl.formatMessage({ id: 'adresy.walidacja.desc' }),
        img: nip,
        link: "https://api.locit.io/docs/?urls.primaryName=identifiers-v2.2.0",
        target: 'blank',
        widget: null,
        widgetVersion: null,
        imageRight: true,
        background: "03_blue.png"
      },
      {
        title: intl.formatMessage({ id: 'adresy.formatowanie.title' }),
        desc: intl.formatMessage({ id: 'adresy.formatowanie.desc' }),
        img: telefon,
        link: "https://api.locit.io/docs/?urls.primaryName=phone-v2.2.0",
        target: 'blank',
        widget: null,
        widgetVersion: null,
        imageRight: false,
        background: "04_blue.png"
      }
    ],
    features: [
      {
        text: intl.formatMessage({ id: 'features.crm' }),
        icon: "bi_crm"
      },
      {
        text: intl.formatMessage({ id: 'features.analytics' }),
        icon: "analytics"
      },
      {
        text: intl.formatMessage({ id: 'features.ecommerce' }),
        icon: "ecommerce"
      }
    ]
  }
}

const Adresy = (props) => (
  <ProductPage data={data(props.intl)} uri={props.uri} pathname={props.location.pathname} />
)

Adresy.propTypes = {
  intl: intlShape.isRequired
};

export default compose(
  withPageContext,
  injectIntl
)(Adresy)
