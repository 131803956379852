import React from 'react'
import { FormattedMessage } from 'react-intl';

export const fullDataBlock = (address) => {
  return (
    <>
      <div>
        <strong><FormattedMessage id="demo.full.address_title" /></strong>
        {address.uuid !== undefined && <p><FormattedMessage id="demo.full.uuid" /><span>{address.uuid}</span></p>}
        {address.voiv !== undefined && <p><FormattedMessage id="demo.full.voiv" /><span>{address.voiv}</span></p>}
        {address.pov !== undefined && <p><FormattedMessage id="demo.full.pov" /><span>{address.pov}</span></p>}
        {address.mun !== undefined && <p><FormattedMessage id="demo.full.mun" /><span>{address.mun}</span></p>}
        {address.zip !== undefined && <p><FormattedMessage id="demo.full.zip" /><span>{address.zip}</span></p>}
        {address.city !== undefined && <p><FormattedMessage id="demo.full.city" /><span>{address.city}</span></p>}
        {address.city_district !== undefined && <p><FormattedMessage id="demo.full.city_district" /><span>{address.city_district}</span></p>}
        {address.prefix !== undefined && <p><FormattedMessage id="demo.full.prefix" /><span>{address.prefix}</span></p>}
        {address.street !== undefined && <p><FormattedMessage id="demo.full.street" /><span>{address.street}</span></p>}
        {address.gus_name !== undefined && <p><FormattedMessage id="demo.full.gus_name" /><span>{address.gus_name}</span></p>}
        {address.current_street !== undefined && <p><FormattedMessage id="demo.full.current_street" /><span>{address.current_street}</span></p>}
        {address.building !== undefined && <p><FormattedMessage id="demo.full.building" /><span>{address.building}</span></p>}
      </div>

      <div>
        <strong><FormattedMessage id="demo.full.xy_title" /></strong>
        {address.y !== undefined && <p><FormattedMessage id="demo.full.y" /><span>{address.y}</span></p>}
        {address.x !== undefined && <p><FormattedMessage id="demo.full.x" /><span>{address.x}</span></p>}
        {address.xy_class !== undefined && <p><FormattedMessage id="demo.full.xy_class" /><span>{address.xy_class}</span></p>}
        {address.xy_class_label !== undefined && <p><FormattedMessage id="demo.full.xy_class_label" /><span>{address.xy_class_label}</span></p>}
      </div>

      <div>
        <strong><FormattedMessage id="demo.full.add_info_title" /></strong>
        {address.mun_type !== undefined && <p><FormattedMessage id="demo.full.mun_type" /><span>{address.mun_type}</span></p>}
        {address.sym !== undefined && <p><FormattedMessage id="demo.full.sym" /><span>{address.sym}</span></p>}
        {address.symd !== undefined && <p><FormattedMessage id="demo.full.symd" /><span>{address.symd}</span></p>}
        {address.mwg7d !== undefined && <p><FormattedMessage id="demo.full.mwg7d" /><span>{address.mwg7d}</span></p>}
        {address.msegm !== undefined && <p><FormattedMessage id="demo.full.msegm" /><span>{address.msegm}</span></p>}
        {address.mwg14 !== undefined && <p><FormattedMessage id="demo.full.mwg14" /><span>{address.mwg14}</span></p>}
        {address.eurogrid62 !== undefined && <p><FormattedMessage id="demo.full.eurogrid62" /><span>{address.eurogrid62}</span></p>}
        {address.ctype !== undefined && <p><FormattedMessage id="demo.full.ctype" /><span>{address.ctype}</span></p>}
        {address.symul !== undefined && <p><FormattedMessage id="demo.full.symul" /><span>{address.symul}</span></p>}
        {address.btype !== undefined && <p><FormattedMessage id="demo.full.btype" /><span>{address.btype}</span></p>}
        {address.bneigh !== undefined && <p><FormattedMessage id="demo.full.bneigh" /><span>{address.bneigh}</span></p>}
        {address.elev !== undefined && <p><FormattedMessage id="demo.full.elev" /><span>{address.elev}</span></p>}
      </div>
    </>
  )
}