import React from "react"
import styles from './gradientSection.module.css'
import PropTypes from "prop-types"

const GradientSection = ({ children, textColor, colorLeft, colorRight }) => (
  <div className="section fp-auto-height" style={{ backgroundColor: colorLeft, background: `linear-gradient(72deg, ${colorLeft} 0%, ${colorRight} 72%)`, color: textColor }}>
    <div className={styles.container}>
      {children}
    </div>
  </div>
)

GradientSection.propTypes = {
  children: PropTypes.node.isRequired,
  colorLeft: PropTypes.string,
  colorRight: PropTypes.string,
  textColor: PropTypes.string
}

GradientSection.defaultProps = {
  colorLeft: '#fff',
  colorRight: '#fff',
  textColor: "#000"
}

export default GradientSection
