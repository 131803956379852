import React, { Component } from 'react'
import styles from "./demoDevAddressAutocomplete.module.css"
import Button from '../../../Button/button';
import ActionButton from '../../../Button/actionButton';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import arta from 'react-syntax-highlighter/dist/esm/styles/hljs/arta';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
SyntaxHighlighter.registerLanguage('json', json);

const isMobile = typeof window !== `undefined` && window.innerWidth <= 768;

class DemoDevBuildingInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: '',
      curl: 'curl -X GET "http://locit.eu/webservice/building-info/v2.2.0/cd6eb8d48d25565a9140c3bfdf21ef93" -H "accept: application/json"',
      code: {
        "info": {
          "code": 200,
          "message": "OK response. Server understood request and returned correct response",
          "machine": "prod1",
          "version": "v2.2.0"
        },
        "data": {
          "stats_address": {
            "uuid": "cd6eb8d48d25565a9140c3bfdf21ef93",
            "zip": "03-140",
            "city": "WARSZAWA",
            "district": "BIAŁOŁĘKA",
            "prefix": "UL",
            "street": "ODKRYTA",
            "gus_cech": "UL.",
            "gus_naz1": "ODKRYTA",
            "gus_naz2": "",
            "building": "38B",
            "y": "52.332022",
            "x": "20.93725",
            "xy_class": "240",
            "ctype": "M",
            "csize": "1764615",
            "msegm": "A1",
            "agglom": "warszawska",
            "elev": "80",
            "sym": "0918123",
            "symd": "0988796",
            "symul": "14732",
            "voiv": "MAZOWIECKIE",
            "pov": "WARSZAWA",
            "mun": "BIAŁOŁĘKA",
            "mun_type": "DZIELNICA WARSZAWY",
            "mwg7": "1465011",
            "mwg7_version": "2018",
            "mwg14": "14650380057483",
            "mwg14_version": "2017",
            "egr_0125": "1kmN3302E5063_413",
            "hex_0125": "586802488",
            "bneigh": "zabudowa mieszkalno-funkcjonalna",
            "btype": "Mieszkalny - wielorodzinny",
            "poc_act": "7",
            "crc_tot": "2",
            "apts_t": "72",
            "pop_apts": "55",
            "pop_tot": "140",
            "popf_tot": "77",
            "popm_tot": "63",
            "popt0004": "13",
            "popt0509": "19",
            "popt1014": "7",
            "popt1519": "13",
            "popt2024": "6",
            "popt2529": "1",
            "popt3034": "9",
            "popt3539": "14",
            "popt4044": "22",
            "popt4549": "21",
            "popt5054": "2",
            "popt5559": "0",
            "popt6064": "6",
            "popt6569": "3",
            "popt7074": "2",
            "popt7599": "2",
            "pop_ax_t": "30.8",
            "pop_ax_y": "40.3",
            "inbld_t": "6860",
            "inbld_w": "7427",
            "poi_szpital": "0",
            "poi_poczta": "0",
            "poi_przedszkole": "0",
            "poi_szk_podst": "0",
            "poi_szk_wyzsza": "0",
            "poi_bankomat": "0",
            "poi_stacja_paliw": "0",
            "poi_centrala_banku": "0",
            "poi_oddzial_banku": "0",
            "poi_hipermarket": "0",
            "poi_supermarket": "0",
            "poi_dyskont": "0",
            "poi_convenience": "0",
            "poi_drogeria": "0",
            "poi_multimedia": "0",
            "poi_wyposazenie": "0",
            "poi_ch": "0",
            "poi_urzad": "0",
            "poi_ambasada": "0",
            "poi_fast_food": "0",
            "poi_muzeum_kino": "0",
            "poi_apteka": "0",
            "poi_pkp": "0",
            "uke_version": "2016-12-31",
            "cpr": "6",
            "cpr_twi": "0",
            "cpr_coa": "2",
            "cpr_fib": "1",
            "mbps": "600",
            "mbps_twi": "0",
            "mbps_coa": "600",
            "mbps_fib": "500",
            "sfp_class": "lata 1996-2000",
            "bheight": "01-03"
          }
        }
      }
    }
  }

  handleChangeUuid = (e) => {
    this.setState({ uuid: e.target.value })
  }

  clearInputs = () => {
    this.setState({
      uuid: ''
    })
  }

  getData = () => {
    const { uuid } = this.state;
    this.fetchBuildingInfo(uuid)
  }

  fetchBuildingInfo = (input) => {
    const { uuid } = this.state;
    const url = 'https://api.locit.dev.beecommerce.pl/building_info/' + input;
    fetch(url)
      .then(response => response.json())
      .then(response => {
        this.setState({
          curl: 'curl -X GET "http://locit.eu/webservice/building-info/v2.2.0/' + uuid + '" -H "accept: application/json"',
          code: response
        })
      })
  }

  render() {
    const { intl } = this.props
    const { uuid, code, curl } = this.state
    return (
      <>
        <div className={styles.devDemo + ' row'}>
          <div className={'col-8 ' + styles.inputs}>
            <div className="row">
              <div className={isMobile ? "col-2 m0 " + styles.label : "col-2"}>
                <span>{intl.formatMessage({ id: 'demo1.dev.uuid_label' })}</span>
              </div>
              <div className={isMobile ? "col-10 m0" : "col-10 mb0"}>
                <label htmlFor="uuid">
                  {intl.formatMessage({ id: 'demo1.dev.uuid_desc' })}
                  <input
                    type="text"
                    name="uuid"
                    value={uuid}
                    onChange={this.handleChangeUuid}
                    autoComplete="off"
                  />
                </label>
              </div>
            </div>
          </div>
          <div className={styles.buttons + ' col-5'}>
            {!isMobile ? <Button text={intl.formatMessage({ id: 'demo.dev.swagger' })} type="green" link="https://api.locit.io/docs/?urls.primaryName=building-info-v2.2.0" target="blank" /> : ''}
            <div className={styles.buttonsContainer}>
              <ActionButton text={intl.formatMessage({ id: 'button.send' })} type="blue" action={this.getData} />
              <ActionButton text={intl.formatMessage({ id: 'button.clear' })} type="transparent" action={this.clearInputs} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className={styles.responses}>{intl.formatMessage({ id: 'demo.dev.responses' })}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-1">
            <span>Curl</span>
          </div>
          <div className="col-11">
            <div className={styles.code}>
              <SyntaxHighlighter language="json" style={arta} customStyle={{ color: '#fff', padding: 15 }}>
                {curl}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-1">
            <span>{intl.formatMessage({ id: 'demo.dev.code' })}</span>
          </div>
          <div className="col-11">
            <div className={styles.code}>
              <SyntaxHighlighter language="json" style={arta} customStyle={{ color: '#fff', padding: 15 }}>
                {JSON.stringify(code, null, 2)}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>
        {isMobile ? <div className="row">
          <div className="col-12"> <Button text={intl.formatMessage({ id: 'demo.dev.swagger' })} type="green" link="https://api.locit.io/docs/?urls.primaryName=building-info-v2.2.0" target="blank" /></div>
        </div> : ''}
      </>
    )
  }
}


export default compose(
  injectIntl
)(DemoDevBuildingInfo)
