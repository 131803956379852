import React, { Component } from 'react'
import styles from "./demoDevAddressAutocomplete.module.css"
import Button from '../../../Button/button';
import ActionButton from '../../../Button/actionButton';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import arta from 'react-syntax-highlighter/dist/esm/styles/hljs/arta';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
SyntaxHighlighter.registerLanguage('json', json);

const isMobile = typeof window !== `undefined` && window.innerWidth <= 768;

class DemoDevAddressAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      curl: 'curl -X POST "http://locit.eu/webservice/address-autocomplete/v2.2.0/" -H "accept: application/json" -H "Content-Type: application/x-www-form-urlencoded" -d "schema=full&query=Mokotowska%2033&format=json&charset=UTF-8"',
      code: {
        "info": {
          "code": 200,
          "message": "OK response. Server understood request and returned correct response",
          "machine": "prod1",
          "version": "v2.2.0"
        },
        "data": {
          "uuid": "0f72eb56ed0b807c1d37eeb4be21341",
          "country": "POL",
          "zip": "02-786",
          "city": "WARSZAWA",
          "district": "URSYNÓW",
          "post": "",
          "sym": "0918123",
          "symd": "0988833",
          "symp": "",
          "prefix": "UL.",
          "street": "ZWIĄZKU WALKI MŁODYCH",
          "building": "12",
          "flat": "",
          "notes": "STOISKO B",
          "strnum_all": "12/STOISKO B",
          "symul": "26299",
          "symul_active": "0",
          "current_symul": "52574",
          "current_prefix": "UL.",
          "current_street": "ROMOCKIEGO-MORRO",
          "corner": "",
          "gus_prefix": "UL.",
          "gus_street": "ZWIĄZKU WALKI MŁODYCH",
          "mwg7": "1465011",
          "mwg7d": "1465138",
          "voiv": "MAZOWIECKIE",
          "pov": "WARSZAWA",
          "mun": "WARSZAWA",
          "mun_type": "GMINA MIEJSKA",
          "y": 52.158815,
          "x": 21.039364,
          "xy_class": 240,
          "xy_class_label": "punkt adresowy",
          "quality": "przypisano identyfikator budynku"
        }
      }
    }
  }

  handleChangeAddress = (e) => {
    this.setState({ address: e.target.value })
  }

  clearInputs = () => {
    this.setState({
      address: ''
    })
  }

  getData = () => {
    const { address } = this.state;
    this.fetchAddress(address)
  }

  fetchAddress = (input) => {
    const { address } = this.state;
    const address_slug = address.replace(/ /g, '%20')
    const url = 'https://api.locit.dev.beecommerce.pl/autocomplete/full/' + input;
    fetch(url)
      .then(response => response.json())
      .then(response => {
        this.setState({
          curl: 'curl -X POST "http://locit.eu/webservice/address-autocomplete/v2.2.0/" -H "accept: application/json" -H "Content-Type: application/x-www-form-urlencoded" -d "schema=full&query=' + address_slug + '&format=json&charset=UTF-8"',
          code: response
        })
      })
  }

  render() {
    const { intl } = this.props
    const { address, code, curl } = this.state
    return (
      <>
        <div className={styles.devDemo + ' row'}>
          <div className={'col-8 ' + styles.inputs}>
            <div className="row">
              <div className={isMobile ? "col-2 m0 " + styles.label : "col-2"}>
                <span>{intl.formatMessage({ id: 'demo1.dev.street_label' })}</span>
              </div>
              <div className={isMobile ? "col-10 m0" : "col-10 mb0"}>
                <label htmlFor="locit-address">
                  {intl.formatMessage({ id: 'demo1.dev.street_desc' })}
                  <input
                    type="text"
                    name="locit-address"
                    value={address}
                    onChange={this.handleChangeAddress}
                    autoComplete="off"
                  />
                </label>
              </div>
            </div>
          </div>
          <div className={styles.buttons + ' col-5'}>
            {!isMobile ? <Button text={intl.formatMessage({ id: 'demo.dev.swagger' })} type="green" link="https://api.locit.io/docs/?urls.primaryName=address-autocomplete-v2.2.0" target="blank" /> : ''}
            <div className={styles.buttonsContainer}>
              <ActionButton text={intl.formatMessage({ id: 'button.send' })} type="blue" action={this.getData} />
              <ActionButton text={intl.formatMessage({ id: 'button.clear' })} type="transparent" action={this.clearInputs} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className={styles.responses}>{intl.formatMessage({ id: 'demo.dev.responses' })}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-1">
            <span>Curl</span>
          </div>
          <div className="col-11">
            <div className={styles.code}>
              <SyntaxHighlighter language="json" style={arta} customStyle={{ color: '#fff', padding: 15 }}>
                {curl}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-1">
            <span>{intl.formatMessage({ id: 'demo.dev.code' })}</span>
          </div>
          <div className="col-11">
            <div className={styles.code}>
              <SyntaxHighlighter language="json" style={arta} customStyle={{ color: '#fff', padding: 15 }}>
                {JSON.stringify(code, null, 2)}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>
        {isMobile ? <div className="row">
          <div className="col-12"> <Button text={intl.formatMessage({ id: 'demo.dev.swagger' })} type="green" link="https://api.locit.io/docs/?urls.primaryName=address-autocomplete-v2.2.0" target="blank" /></div>
        </div> : ''}
      </>
    )
  }
}

export default compose(
  injectIntl
)(DemoDevAddressAutocomplete)
