import React from 'react'
import { FormattedMessage } from 'react-intl';

export const fullDataBlock = (address) => {
  return (
    <>
      <div>
        <strong><FormattedMessage id="demo.full.address_title" /></strong>
        {address.uuid !== undefined && <p><FormattedMessage id="demo.full.uuid" /><span>{address.uuid}</span></p>}
        {address.country !== undefined && <p><FormattedMessage id="demo.full.country" /><span>{address.country}</span></p>}
        {address.zip !== undefined && <p><FormattedMessage id="demo.full.zip" /><span>{address.zip}</span></p>}
        {address.city !== undefined && <p><FormattedMessage id="demo.full.city" /><span>{address.city}</span></p>}
        {address.district !== undefined && <p><FormattedMessage id="demo.full.city_district" /><span>{address.district}</span></p>}
        {address.post !== undefined && <p><FormattedMessage id="demo.full.post" /><span>{address.post}</span></p>}
        {address.prefix !== undefined && <p><FormattedMessage id="demo.full.prefix" /><span>{address.prefix}</span></p>}
        {address.street !== undefined && <p><FormattedMessage id="demo.full.street" /><span>{address.street}</span></p>}
        {address.building !== undefined && <p><FormattedMessage id="demo.full.building" /><span>{address.building}</span></p>}
        {address.flat !== undefined && <p><FormattedMessage id="demo.full.flat" /><span>{address.flat}</span></p>}
        {address.strnum_all !== undefined && <p><FormattedMessage id="demo.full.strnum_all" /><span>{address.strnum_all}</span></p>}
        {address.voiv !== undefined && <p><FormattedMessage id="demo.full.voiv" /><span>{address.voiv}</span></p>}
        {address.pov !== undefined && <p><FormattedMessage id="demo.full.pov" /><span>{address.pov}</span></p>}
        {address.mun !== undefined && <p><FormattedMessage id="demo.full.mun" /><span>{address.mun}</span></p>}
      </div>

      <div>
        <strong><FormattedMessage id="demo.full.xy_title" /></strong>
        {address.y !== undefined && <p><FormattedMessage id="demo.full.y" /><span>{address.y}</span></p>}
        {address.x !== undefined && <p><FormattedMessage id="demo.full.x" /><span>{address.x}</span></p>}
        {address.xy_class !== undefined && <p><FormattedMessage id="demo.full.xy_class" /><span>{address.xy_class}</span></p>}
        {address.xy_class_label !== undefined && <p><FormattedMessage id="demo.full.xy_class_label" /><span>{address.xy_class_label}</span></p>}
      </div>

      <div>
        <strong><FormattedMessage id="demo.full.add_info_title" /></strong>
        {address.sym !== undefined && <p><FormattedMessage id="demo.full.sym" /><span>{address.sym}</span></p>}
        {address.symd !== undefined && <p><FormattedMessage id="demo.full.symd" /><span>{address.symd}</span></p>}
        {address.symp !== undefined && <p><FormattedMessage id="demo.full.symp" /><span>{address.symp}</span></p>}
        {address.notes !== undefined && <p><FormattedMessage id="demo.full.notes" /><span>{address.notes}</span></p>}
        {address.symul !== undefined && <p><FormattedMessage id="demo.full.symul" /><span>{address.symul}</span></p>}
        {address.symul_active !== undefined && <p><FormattedMessage id="demo.full.symul_active" /><span>{address.symul_active}</span></p>}
        {address.current_symul !== undefined && <p><FormattedMessage id="demo.full.current_symul" /><span>{address.current_symul}</span></p>}
        {address.current_prefix !== undefined && <p><FormattedMessage id="demo.full.current_prefix" /><span>{address.current_prefix}</span></p>}
        {address.current_street !== undefined && <p><FormattedMessage id="demo.full.current_street" /><span>{address.current_street}</span></p>}
        {address.corner !== undefined && <p><FormattedMessage id="demo.full.corner" /><span>{address.corner}</span></p>}
        {address.gus_prefix !== undefined && <p><FormattedMessage id="demo.full.gus_prefix" /><span>{address.gus_prefix}</span></p>}
        {address.gus_street !== undefined && <p><FormattedMessage id="demo.full.gus_street" /><span>{address.gus_street}</span></p>}
        {address.mwg7 !== undefined && <p><FormattedMessage id="demo.full.mwg7" /><span>{address.mwg7}</span></p>}
        {address.mwg7d !== undefined && <p><FormattedMessage id="demo.full.mwg7d" /><span>{address.mwg7d}</span></p>}
        {address.mun_type !== undefined && <p><FormattedMessage id="demo.full.mun_type" /><span>{address.mun_type}</span></p>}
        {address.quality !== undefined && <p><FormattedMessage id="demo.full.quality" /><span>{address.quality}</span></p>}
      </div>
    </>
  )
}